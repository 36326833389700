<template>
    <div class="content">
        <div v-if='loading' class='overlay' />

        <md-progress-spinner v-if='loading' class='loading'  md-mode="indeterminate"
                             :md-diameter="100" :md-stroke="10" />

        <div class="filters-block">
            <div class="filters-inputs-block">
                <div class="filters-arbo-and-date">
                    <device-filter-site @storeSelected="arboChange" :arbo="arbo" :clear-all-arbo="clearAllArbo"/>
                    <wipe-date-picker class="date-picker"
                                      @isSelectedDate="manageSelectedDate"
                                      :already-selected-dates="alreadySelectedDates"
                                      @validSelectedDates="getAndCalculateMigrationStats"/>
                </div>
                <div class="icon-and-text-clear-filters" @click="clearAllFilters">
                    <bin />
                    <span v-if="!$vuetify.breakpoint.xs" class="text-button-clear-filters">CLEAR ALL FILTERS</span>
                </div>
                <v-spacer></v-spacer>
            </div>
            <div v-if="countriesSelectedCount > 0 || storesSelectedCount > 0" class="selected-filters">
                <span class="selected-filters-text"> {{ storesSelectedCount }} {{ `${storesSelectedCount > 1 ? 'stores are' : 'store is'}`}}
                    selected in {{ countriesSelectedCount }} {{ countriesSelectedCount > 1 ? 'countries' : 'country'}}
                </span><span v-if="isSelectedDate || alreadySelectedDates" class="selected-filters-text">{{ constants.DATE_FILTER_SELECTED_SUFFIX }}</span>
            </div>
            <div v-else-if="isSelectedDate || alreadySelectedDates" class="selected-filters">
                <span class="selected-filters-text"> {{ constants.DATE_FILTER_SELECTED }} </span>
            </div>
            <div v-else class="selected-filters">
                <span class="selected-filters-text"> {{ constants.ENTIRE_DATABASE_FETCHED }} </span>
            </div>
        </div>


        <div class="metrics-content">
            <div class="metrics-left-block">
                <div class="title-and-chart">
                    <div class="title">Wiped devices from MDM</div>
                    <NoData v-if="displayNoData" />
                    <div v-if="displayNoData" class="error-message-stats">An error occurred while fetching data</div>
                    <DonutChart v-else
                                :sections="wipedDevicesSectionsComputed"
                                @section-mouseover="handleMouseOver"
                                @section-mouseout="handleMouseOut"
                                :total="wipeStats.wipedCount"
                                :percentage="wipeStats.wipedPercentage < 1 ? wipeStats.wipedPercentageMoreDigits : wipeStats.wipedPercentage"
                                :action="constants.WIPED"
                    />
                </div>
                <tooltip-donut-chart v-if="displayTooltipWiped"
                                   :title="tooltipTitle"
                                   :value="tooltipValue"
                                   :top="tooltipPosition.top"
                                   :left="tooltipPosition.left"
                                   :legend-rect-color="tooltipRectColor"
                />
                <div v-if="displayWipedInfThanOnePercentMessage && wipeStats.wipedCount > 0" style="margin-top: 20px">* The wiped devices section may not appear on the chart because it is < 1%.</div>
            </div>
            <div class="metrics-right-block">
                <div class="title-and-chart">
                    <div class="title">Migrated devices to Workspace One</div>
                    <NoData v-if="displayNoData" />
                    <div v-if="displayNoData" class="error-message-stats">An error occurred fetching data</div>
                    <DonutChart v-else
                                :sections="migratedDevicesSectionsComputed"
                                @section-mouseover="handleMouseOver"
                                @section-mouseout="handleMouseOut"
                                :total="migrationStats.migratedCount"
                                :percentage="migrationStats.migratedPercentage < 1 ? migrationStats.migratedPercentageMoreDigits : migrationStats.migratedPercentage"
                                :action="constants.MIGRATED"
                    />
                </div>
                <tooltip-donut-chart v-if="displayTooltipMigrated"
                                   :title="tooltipTitle"
                                   :value="tooltipValue"
                                   :top="tooltipPosition.top"
                                   :left="tooltipPosition.left"
                                   :legend-rect-color="tooltipRectColor"
                />
                <div v-if="displayMigratedInfThanOnePercentMessage && migrationStats.migratedCount > 0" style="margin-top: 20px">* The wiped devices section may not appear on the chart because it is < 1%.</div>
            </div>
        </div>
    </div>
</template>

<script>
import DeviceSearchTable from "../device-list/device-search/device-search-table.vue";
import DonutChart from "./donut-chart.vue";
import TooltipDonutChart from "./tooltip-donut-chart.vue";
import {store} from "../../../store/store";
import Vue from "vue";
import Moment from "moment";
import NoData from "../icons/no-data-icon.vue";
import ConstantsMigration from "../constants-migration";
import FilterBySite from "../icons/filter-by-site-icon.vue";
import DeviceFilterSite from "../device-list/device-search/device-filter-site.vue";
import LocalStorageService from "../../../service/local-storage-service";
import LocalStorageConstant from "../../../constant/local-storage-constant";
import WipeDatePicker from "../components/wipe-date-picker.vue";
import localStorageService from "../../../service/local-storage-service";
import localStorageConstant from "../../../constant/local-storage-constant";
import Bin from "../icons/bin-icon.vue";

export default {
    name: 'DeviceMetric',
    components: {DeviceFilterSite, NoData, DonutChart, TooltipDonutChart, DeviceSearchTable, FilterBySite, WipeDatePicker, Bin},
    data() {
        return {
            displayTooltipMigrated: false,
            displayTooltipWiped: false,
            displayWipedInfThanOnePercentMessage: false,
            displayMigratedInfThanOnePercentMessage: false,
            displayNoData: false,
            tooltipTitle: "",
            tooltipValue: "",
            tooltipPosition: {
                top: 0,
                left: 0
            },
            tooltipRectColor: "",
            countries: [],
            stores: [],
            dateLastCheck: Moment().subtract(3, 'months').format('YYYY-MM-DD'),
            totalDevices: 0,
            wipeStats: {
                wipedCount: 0,
                notWipedCount: 0,
                wipedPercentage: 0,
                wipedPercentageMoreDigits: 0,
                notWipedPercentage: 0,
            },
            migrationStats: {
                migratedPercentage: 0,
                migratedPercentageMoreDigits: 0,
                notMigratedPercentage: 0,
                migratedCount: 0,
                notMigratedCount: 0,
            },
            loading: false,
            constants: ConstantsMigration,
            arbo: LocalStorageService.getJsonItem(LocalStorageConstant.MIGRATION.ACTIVE_ARBO),
            isSelectedDate: false,
            alreadySelectedDates: localStorageService.getJsonItem(localStorageConstant.MIGRATION.ACTIVE_DATE_FILTERS),
            startWipeDate: "",
            endWipeDate: "",
            clearAllArbo: {}
        }
    },
    methods: {
        handleMouseOut(section) {
            section.label.toLowerCase().includes(ConstantsMigration.WIPED) ? this.displayTooltipWiped = false : this.displayTooltipMigrated = false;
        },

        handleMouseOver(section, event) {
            section.label.toLowerCase().includes(ConstantsMigration.WIPED) ? this.displayTooltipWiped = true : this.displayTooltipMigrated = true;
            this.tooltipTitle = section.label;
            this.tooltipValue = this.calculateTooltipValue(section);
            this.tooltipRectColor = section.color;

            this.calculateTooltipPosition(section, event);

        },
        calculateTooltipPosition(section, event) {
            const chartContainers = Array.from(this.$el.querySelectorAll('.cdc-filler'));
            let chartContainer;
            if(section.label.toLowerCase().includes(ConstantsMigration.WIPED)) {
                chartContainer = chartContainers.find(el => el.title.toLowerCase().includes(ConstantsMigration.WIPED));
            } else {
                chartContainer = chartContainers.find(el => el.title.toLowerCase().includes(ConstantsMigration.MIGRATED));
            }
            const chartRect = chartContainer.getBoundingClientRect();
            const rect = event.target.getBoundingClientRect();
            const baseTop = rect.top - chartRect.top + rect.height;
            const baseLeft = rect.left - chartRect.left + rect.width;

            if(section.label.toLowerCase().includes(ConstantsMigration.WORD_TO_FILTER_BY_LABEL)) {
                this.getTooltipPosNotWipedOrMigrated(section, baseTop, baseLeft);
            } else {
                this.getTooltipPosWipedOrMigrated(section, baseTop, baseLeft)
            }
        },
        getTooltipPosWipedOrMigrated(section, baseTop, baseLeft) {
            const positions = [
                { max: 5, top: -130, left: window.innerWidth < 900 ? -50 : 75 },
                { max: 20, top: -80, left: window.innerWidth < 900 ? -50 : 50 },
                { max: 30, top: 50, left: window.innerWidth < 900 ? 0 : 50 },
                { max: 40, top: -30, left: window.innerWidth < 900 ? -50 : 75 },
                { max: 47, top: -30, left: window.innerWidth < 900 ? 50 : 150 },
                { max: 53, top: -30, left: window.innerWidth < 900 ? 100 : 200 },
                { max: Infinity, top: -30, left: window.innerWidth < 900 ? 100 : 200 }
            ];

            for (const pos of positions) {
                if (section.value <= pos.max) {
                    this.tooltipPosition = {
                        top: baseTop + pos.top,
                        left: baseLeft + pos.left
                    };
                    break;
                }
            }
        },
        getTooltipPosNotWipedOrMigrated(section, baseTop, baseLeft) {
            const positions = [
                { max: 5, top: -130, left: window.innerWidth < 900 ? 100 : 250 },
                { max: 30, top: -50, left: window.innerWidth < 900 ? 100 : 200 },
                { max: 47, top: -30, left: window.innerWidth < 900 ? 75 : 150 },
                { max: 53, top: -30, left: window.innerWidth < 900 ? 75 : 100 },
                { max: 80, top: 20, left: window.innerWidth < 900 ? 25 : 100 },
                { max: 85, top: -30, left: window.innerWidth < 900 ? -50 : 50 },
                { max: 95, top: 20, left: window.innerWidth < 900 ? -100 : 25 },
                { max: Infinity, top: 20, left: window.innerWidth < 900 ? -50 : 75 }
            ];

            for (const pos of positions) {
                if (section.value <= pos.max) {
                    this.tooltipPosition = {
                        top: baseTop + pos.top,
                        left: baseLeft + pos.left
                    };
                    break;
                }
            }
        },

        calculateTooltipValue(section) {
            if(section.label.toLowerCase().includes(ConstantsMigration.MIGRATED)) {
                return `${section.value} % (${section.label.toLowerCase().includes(ConstantsMigration.WORD_TO_FILTER_BY_LABEL) ? this.migrationStats.notMigratedCount : this.migrationStats.migratedCount} devices)`;
            }
            return `${section.value} % (${section.label.toLowerCase().includes(ConstantsMigration.WORD_TO_FILTER_BY_LABEL) ? this.wipeStats.notWipedCount : this.wipeStats.wipedCount} devices)`;
        },
        async getMigrationStats() {
            this.loading = true;
            this.countries = [];
            this.stores = [];
            if(this.arbo) {
                this.arbo.retail.site.forEach(country => {
                    if(country.checked) this.countries.push(country.ref)
                    country.sites.forEach(store => {
                        if(store.checked) this.stores.push(store.id.toString())
                    });
                })
            }
            let metrics;
            try {
                metrics = await Vue.http.post(store.state.urlMigrationMetrics, {
                date_last_check: this.dateLastCheck,
                countries: this.countries,
                stores: this.stores,
                start_wipe_date: this.startWipeDate,
                end_wipe_date: this.endWipeDate
                })
            } catch(e) {
                console.error("getMigrationStats, error: ", e);
                this.loading = false;
                this.displayNoData = true;
            }
            this.loading = false;
            this.totalDevices = metrics.body.total;
            this.wipeStats.wipedCount = metrics.body.wiped.done;
            this.wipeStats.notWipedCount = metrics.body.wiped.todo;
            this.migrationStats.migratedCount = metrics.body.migrated.done;
            this.migrationStats.notMigratedCount = metrics.body.migrated.todo;
        },
        calculatePercentages() {
            this.wipeStats.wipedPercentage = this.wipeStats.wipedCount ? parseFloat(((this.wipeStats.wipedCount / this.totalDevices) * 100).toFixed(1)) : 0;
            this.wipeStats.wipedPercentageMoreDigits = this.wipeStats.wipedCount ? parseFloat(((this.wipeStats.wipedCount / this.totalDevices) * 100).toFixed(5)) : 0;
            this.wipeStats.notWipedPercentage = parseFloat((100 - this.wipeStats.wipedPercentage).toFixed(1));
            this.migrationStats.migratedPercentage = this.migrationStats.migratedCount ? parseFloat(((this.migrationStats.migratedCount / this.totalDevices) * 100).toFixed(1)) : 0;
            this.migrationStats.migratedPercentageMoreDigits = this.migrationStats.migratedCount ? parseFloat(((this.migrationStats.migratedCount / this.totalDevices) * 100).toFixed(5)) : 0;
            this.migrationStats.notMigratedPercentage = parseFloat((100 - this.migrationStats.migratedPercentage).toFixed(1));
        },
        async arboChange(arbo){
            this.arbo = arbo;
            await this.getAndCalculateMigrationStats();
            this.$store.commit('migration/setIsLoadingArboAfterReset', false);
        },
        async manageSelectedDate(value) {
            this.isSelectedDate = value;
            if(value === false) {
                this.alreadySelectedDates = null;
                this.endWipeDate = null;
                this.startWipeDate = null;
                await this.getAndCalculateMigrationStats();
            }
        },
        async clearAllFilters() {
            this.alreadySelectedDates = [];
            this.isSelectedDate = false;
            LocalStorageService.remove(LocalStorageConstant.MIGRATION.ACTIVE_ARBO);
            LocalStorageService.remove(LocalStorageConstant.MIGRATION.ACTIVE_DATE_FILTERS);
            this.resetArbo();
            await this.getAndCalculateMigrationStats();
            this.$store.commit('migration/setIsLoadingArboAfterReset', false);
        },
        async getAndCalculateMigrationStats(dates) {
            this.displayWipedInfThanOnePercentMessage = false;
            this.displayMigratedInfThanOnePercentMessage = false;
            if(dates?.length > 0) {
                this.startWipeDate = dates[0];
                this.endWipeDate = dates[1] ? dates[1] : dates[0];
            }
            await this.getMigrationStats();
            this.calculatePercentages();
            if(this.wipeStats.wipedPercentage < 1) this.displayWipedInfThanOnePercentMessage = true;
            if(this.migrationStats.migratedPercentage < 1) this.displayMigratedInfThanOnePercentMessage = true;
        },
        resetArbo() {
            this.arbo.checked = false;
            this.arbo['retail'].checked = false;
            this.arbo['retail'].site.forEach(country => {
                country.checked = false;
                country.sites.forEach(store => {
                    store.checked = false;
                });
            });
            localStorageService.setItem(localStorageConstant.MIGRATION.ACTIVE_ARBO, this.arbo);
            this.clearAllArbo = this.arbo;
        }
    },
    computed: {
        wipedPercentageComputed() {
            return this.wipeStats.wipedPercentage;
        },
        notWipedPercentageComputed() {
            return this.wipeStats.notWipedPercentage;
        },
        wipedDevicesSectionsComputed() {
            return [
                { label: ConstantsMigration.WIPED_FROM_MDM, value: this.wipedPercentageComputed, color: ConstantsMigration.COLORS.BLUE_BRAND },
                { label: ConstantsMigration.NOT_WIPED_FROM_MDM, value: this.notWipedPercentageComputed, color: this.countriesSelectedCount > 0 || this.storesSelectedCount > 0 || this.isSelectedDate || this.alreadySelectedDates ? ConstantsMigration.COLORS.RED_STATUS_NEGATIVE_LIGHT : ConstantsMigration.COLORS.RED_STATUS_NEGATIVE }
            ];
        },
        migratedPercentageComputed() {
            return this.migrationStats.migratedPercentage;
        },
        notMigratedPercentageComputed() {
            return this.migrationStats.notMigratedPercentage;
        },
        migratedDevicesSectionsComputed() {
            return [
                { label: ConstantsMigration.MIGRATED_TO_WSO, value: this.migratedPercentageComputed, color: ConstantsMigration.COLORS.BLUE_BRAND },
                { label: ConstantsMigration.NOT_MIGRATED_TO_WSO, value: this.notMigratedPercentageComputed, color: this.countriesSelectedCount > 0 || this.storesSelectedCount > 0 || this.isSelectedDate || this.alreadySelectedDates ? ConstantsMigration.COLORS.RED_STATUS_NEGATIVE_LIGHT : ConstantsMigration.COLORS.RED_STATUS_NEGATIVE }
            ];
        },
        storesSelectedCount() {
            let storesCounts = 0;
            if(this.arbo) {
                this.arbo['retail'].site.forEach(country => {
                    country.sites.forEach(store => {
                        if(store.checked) storesCounts ++ ;
                    });
                });
            }
            return storesCounts;
        },
        countriesSelectedCount() {
            let countriesCounts = 0;

            if(this.arbo) {
                this.arbo['retail'].site.forEach(country => {
                    const hasMinOneStoreChecked = country.sites.some(store => store.checked)
                    if(hasMinOneStoreChecked) countriesCounts ++;
                });
            }
            return countriesCounts;
        }
    },
    async mounted() {
        await this.getAndCalculateMigrationStats(this.alreadySelectedDates);
    }
};
</script>

<style scoped lang="scss">
@import '../../devices/all.scss';

.content {
    padding-top: 0 !important;
    width: 100%;
}

.filters-inputs-block {
    width: 100%;
    background-color: var(--vtmn-color_white);
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.metrics-content {
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.metrics-block {
    width: 50%;
    background-color: var(--vtmn-color_white);
    padding: 30px;
}

.metrics-left-block {
    @extend .metrics-block;
    margin-right: 20px;
    position: relative;
}

.metrics-right-block {
    @extend .metrics-block;
    position: relative;
}

.v-application .primary {
    background-color: transparent!important;
}

device-filter-site {
    flex: 0 0 33.33%;
}

.title {
    color: #3643BA;
    margin-bottom: 30px;
    font-size: 22px;
}

.title-and-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-message-stats {
    margin-top: 15px;
    color: #D70321;
    font-weight: 600;
}

.selected-filters {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    gap: 5px;
}

.selected-filters-text {
    color: #149B65;
    font-weight: 500;
    font-size: 18px;
}

.filters-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--vtmn-color_white);
}

.date-picker {
    margin-left: 50px;
    margin-top: 40px;
    display: flex;
}

.icon-and-text-clear-filters {
    display: flex;
    align-items: center;
    margin-left: 40px;
}

.icon-and-text-clear-filters:hover {
    cursor: pointer;
}

.text-button-clear-filters {
    color: #3643BA;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
}

.filters-arbo-and-date {
    display: flex;
}




@media screen and (max-width: 600px) {
    .metrics-content {
        flex-direction: column;
    }

    .metrics-block {
        width: 100%;
    }

    .filters-block {
        width: 100%;
    }

    .metrics-left-block {
        @extend .metrics-block;
        margin-bottom: 20px;
    }

    .selected-filters-text {
        font-size: 10px;
    }

    .icon-and-text-clear-filters {
        margin-right: 20px;
    }
}
</style>
