<template>
    <div>
        <v-container class="search">
            <v-row align="center">
                <v-col cols="7" class="pr-0">
                    <device-general-search id="devices-full-search"
                                           placeholder="Serial numbers..."
                                           :has-to-showlabel="false"
                                           :hasToCheckNumericType="false"
                                           @startSearch="reInitAndLoadFilteredData"
                                           @criteriaSelected="getSelectedCriteria"
                                           :value="activeFilters"
                                           :already-selected-error="alreadySelected"
                                           @updateAlreadySelected="updateAlreadySelected"
                    />
                </v-col>
                <v-col cols="3" class="pl-0">
                    <device-filter-site @storeSelected="arboChange" :arbo="arbo"/>
                </v-col>

                <v-col cols="2" align="center">
                    <div v-if="csvExport.loading" class="progressSpinner">
                        <v-progress-circular
                            :rotate="-90"
                            :size="50"
                            :width="5"
                            :value="spinnerProgressStatus"
                            :color="ConstantsMigration.COLORS.BLUE_BRAND"
                        >
                            {{ spinnerProgressStatus }}
                        </v-progress-circular>
                    </div>
                    <div v-else-if="csvExport.error">
                        <mdm-toaster :modelValue="csvExport.error" @close="csvExport.error = false">
                            <p>An error occurred when trying to build the export, contact your administrator or try to
                                change your filters.</p>
                        </mdm-toaster>
                    </div>
                    <div v-else>
                        <button id="migration-export-button" @click="exportToCsv">
                            <span class="export-text-and-icon">EXPORT<export-icon class="ml-2"/></span>
                        </button>
                    </div>

                </v-col>
            </v-row>
        </v-container>

        <div class="content">
            <div v-if='loading' class='overlay'/>

            <md-progress-spinner v-if='loading' class='loading' md-mode="indeterminate"
                                 :md-diameter="100" :md-stroke="10"/>

            <div v-if='filteredData'>
                <div v-if='this.nbOfDevice <= 0' class='noResult'>{{ Constants.DEVICE.SEARCH.NO_SEARCH_RESULTS }}</div>
            </div>

            <div class="device-list-header">
                <div class="pagination-container">
                    <pagination :page-size="PAGE_SIZE" :nb-of-elems="nbOfDevice" :elems-total="devicesTotal"
                                :current-page="currentPage" :isStatusFilter="isStatusFilter"
                                @changePage="changeCurrentPage"/>
                </div>
                <div class="legend-container">
                    <Legend @startSearch="setStatusFiltersAndLoadFilteredData"/>
                </div>
            </div>

            <div class="content-all-devices">
                <device-search-table :devices="filteredData" :order-by="orderBy" :headers="headers"
                                     @startSearch="setOrderByAndLoadFilteredData"/>

                <mdm-toaster :modelValue="devicesError" @close="devicesError = false">
                    <p>{{ Constants.DEVICE.SEARCH.ERROR_SEARCHING_DEVICES }}</p>
                </mdm-toaster>
            </div>
        </div>

        <md-button v-if='filterFix' class="md-raised topbutton" @click="topFunction()" id="myBtn" title="Go to top">
            <md-icon>keyboard_arrow_up</md-icon>
            Top
        </md-button>
    </div>
</template>

<script>

import DeviceFilter from "../../../components/Filter/device-filter";
import FieldConstant from "../../../utils/field-constant";
import DeviceGeneralSearch from "./device-search/device-general-search";
import "vue-material/dist/theme/default.css";
import DeviceSearchTable from "./device-search/device-search-table";
import MdmVtmnPopup from "../../../components/mdm/vitamine/popup/mdm-vtmn-popup";
import DeviceSelectDataToDisplay from "../../devices/search/device-select-data-to-display";
import DeviceSearchConstants from "../../../utils/device-search-constant";
import LocalStorageConstant from "../../../constant/local-storage-constant";
import LocalStorageService from "../../../service/local-storage-service";
import DeviceFilterColumns from "../../../components/Filter/device-filter-columns";
import DeviceFilterColumnsAlert from "../../../components/Alerts/device-filter-columns-alert";
import DeviceFilterSite from "./device-search/device-filter-site";
import DeviceFilterService from "../../../service/devices/device-filter-service";
import Legend from './legend.vue';
import deviceService from "@/service/devices/device-service";
import Constants from "../../../constant/constants";
import ExportService from "../../../service/devices/export-service";
import ExportIcon from "../icons/export-icon.vue";
import ConstantsMigration from "../constants-migration";

export default {
    components: {
        ExportIcon,
        Legend,
        DeviceFilter,
        DeviceGeneralSearch,
        MdmVtmnPopup,
        DeviceSelectDataToDisplay,
        DeviceFilterColumns,
        DeviceFilterColumnsAlert,
        DeviceSearchTable,
        DeviceFilterSite
    },
    data: function () {
        return {
            alreadySelected: false,
            NO_VALUE: FieldConstant.TEXT.NO_VALUE,
            PAGE_SIZE: FieldConstant.DEVICE.SEARCH.PAGE_SIZE,
            arbo: LocalStorageService.getJsonItem(LocalStorageConstant.MIGRATION.ACTIVE_ARBO),
            loading: false,
            devices: null,
            devicesTotal: 0,
            nbOfDevice: 0,
            currentPage: 1,
            orderBy: {
                key: undefined,
                order: undefined,
            },
            allColumns: [
                {displayName: 'Serial number', id: 'serialNumber', idt: 'serial_number'},
                {displayName: 'Type', id: 'deviceType', idt: 'device_type'},
                {displayName: 'Ownership', id: 'isProDevice', idt: 'is_pro_device'},
                {displayName: 'Country', id: 'country', idt: 'country'},
                {displayName: 'Site', id: 'siteId', idt: 'site_id'},
                {displayName: 'WIFI', id: 'wlan', idt: 'wlan'},
                {displayName: 'MDM Status', id: 'mdmStatus', idt: 'mdm_status'},
                {displayName: 'WSO Status', id: 'wsoStatus', idt: 'wso_status'},
                {displayName: 'Migration', id: 'migrationStatus', idt: 'migration_status'},
                {displayName: 'Last Check', id: 'dateLastCheck', idt: 'date_last_check'}
            ],
            activeFilters: [],
            selectedCriteria: [],
            filterFix: false,
            filteredData: [],
            headers: this.getDisplayedHeaders(),
            devicesError: false,
            Constants,
            csvExport: {
                loading: false,
                error: false,
            },
            statusFilter: {},
            isStatusFilter: false
        };
    },
    mounted: async function () {
        if (LocalStorageService.getItem(LocalStorageConstant.MIGRATION.ACTIVE_FILTERS)) {
            this.activeFilters = DeviceFilterService.parseLocalStorageActiveFilter(LocalStorageService.getJsonItem(LocalStorageConstant.MIGRATION.ACTIVE_FILTERS));
        }
        this.loading = true;
        await this.loadFilteredData();
        this.loading = false;
        window.addEventListener("scroll", this.handleScroll);
    },
    watch: {
        activeFilters: {
            handler() {
                LocalStorageService.setItem(LocalStorageConstant.MIGRATION.ACTIVE_FILTERS, {
                    activeFilters: this.activeFilters,
                    version: LocalStorageConstant.FILTER_VERSION
                });
            }
        },
    },
    computed: {
        ConstantsMigration() {
            return ConstantsMigration
        },
        spinnerProgressStatus() {
            return this.$store.state.devices.devices.exportSpinnerProgressStatus;
        }
    },
    methods: {
        updateAlreadySelected(alreadySelected) {
            this.alreadySelected = alreadySelected;
        },
        arboChange(arbo) {
            this.arbo = arbo;
            this.isStatusFilter = false;
            this.loadFilteredData();
        },
        displayColumnForMobile() {
            return [
                'serialNumber',
                'type',
                'ownership',
                'country',
                'site',
                'wifi',
                'mdmStatus',
                'wsoStatus',
                'migrationStatus',
                'dateLastCheck'
            ];
        },
        // Reinit pagination
        reInitAndLoadFilteredData() {
            this.alreadySelected = false;
            this.currentPage = 1;
            this.orderBy = null;
            this.isStatusFilter = false;
            this.loadFilteredData();
        },
        setOrderByAndLoadFilteredData(orderBy) {
            this.orderBy = orderBy;
            this.isStatusFilter = false;
            this.loadFilteredData();
        },
        setStatusFiltersAndLoadFilteredData(statusFilter) {
            this.statusFilter = statusFilter;
            this.isStatusFilter = true;
            this.currentPage = 1;
            this.loadFilteredData();
        },
        getSelectedCriteria(criterias) {
            this.selectedCriteria = criterias;
        },
        loadFilteredData: async function () {
            this.devicesError = false;
            try {
                this.loading = true;
                const {filteredData, devicesTotal, nbOfDevice} = await deviceService.searchMigrationDevices(
                    this.activeFilters, {
                        page: this.currentPage,
                        pageSize: this.PAGE_SIZE
                    }, this.orderBy, this.arbo, this.statusFilter);
                this.filteredData = filteredData;
                this.devicesTotal = devicesTotal;
                this.nbOfDevice = nbOfDevice;
                this.loading = false;
                this.$store.commit('migration/setIsLoadingArboAfterReset', false);
            } catch (e) {
                console.error("We are unable to load devices due to an error", e)
                this.devicesError = true;
            } finally {
                this.loading = false;
            }
        },
        topFunction: function () {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        changeCurrentPage: function (page) {
            this.currentPage = page;
            this.isStatusFilter = false;
            this.loadFilteredData();
        },
        handleScroll: function () {
            this.filterFix = !this.$vuetify.breakpoint.mobile && (document.body.scrollTop > 320 || document.documentElement.scrollTop > 320 || window.pageYOffset > 320);
        },
        getDisplayedHeaders: function (headers) {
            headers = DeviceSearchConstants.MIGRATION_HEADERS.filter(header => header.default === true || header.selected === true)

            this.pushLastCheckToTheEnd(headers)
            this.refreshTable(headers)
            return headers
        },
        pushLastCheckToTheEnd: function (headers) {
            let headersFormatted = headers
            let lastCheckHeader = headers.filter(header => header.value === "dateLastCheck.value");
            headersFormatted.push(lastCheckHeader[0])
            let index = headersFormatted.indexOf(headersFormatted.find(header => header.value === "dateLastCheck.value"));
            headersFormatted.splice(index, 1)
            return headersFormatted
        },
        refreshTable(headers) {
            this.headers = headers;
        },
        async exportToCsv() {
            this.$store.commit('devices/setExportSpinnerProgressStatus', 0);
            this.csvExport.loading = true;
            try {
                this.$store.commit('migration/setIsMigrationExport', true)
                await ExportService.buildFrom(this.activeFilters, this.orderBy, this.arbo, this.statusFilter);
            } catch (e) {
                console.error("[Error] CSV export : " + e.stack);
                this.csvExport.error = true
            }
            this.csvExport.loading = false;
        }
    }
}
</script>


<style scoped lang="scss">

@import '../../devices/all.scss';

.mainSearch {
    background-color: #efefef;
    border-top: solid 1px var(--vtmn-color_grey-light-3);
}

.search {
    border: none !important;
    padding-left: 20px !important;
    padding-bottom: 0 !important; /* Ensure no extra padding at the bottom */
}

.content {
    padding-top: 0 !important;
}

.content-all-devices {
    max-height: 80vh; /* Adjust height as needed */
    overflow-y: auto;
}

.v-application .primary {
    background-color: transparent !important;
}

.device-list-header {
    display: grid;
    grid-template-columns: repeat(12, 1fr); /* Creates 12 equal columns */
    gap: 10px; /* Adjust the gap as needed */
}

.pagination-container {
    grid-column: span 5; /* Spans 5 columns */
}

.legend-container {
    grid-column: span 7; /* Spans 7 columns */
}

.search-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* or center if you want to center vertically as well */
    gap: 1rem; /* space between the components */
}

device-general-search {
    flex: 0 0 66.67%; /* 6 out of 9 columns */
}

device-filter-site {
    flex: 0 0 33.33%; /* 3 out of 9 columns */
}

#migration-export-button {
    border: 1px solid #949494;
    padding: 10px 20px 10px 20px;
}

.export-text-and-icon {
    display: flex;
}


</style>
