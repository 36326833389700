export default {
    getFormattedFirmwareVersions(firmware_build_versions) {
        if (Array.isArray(firmware_build_versions)) {
            return sortByAndroidVersion(firmware_build_versions)
                .map(version => `${version.android_version}: ${version.firmware_build_version}`);
        }
        return [];
    }
};

const sortByAndroidVersion = (firmwareBuildVersions) => {
    return firmwareBuildVersions.sort((item1, item2) => {
        const versionA = item1.android_version.split(".").map(Number);
        const versionB = item2.android_version.split(".").map(Number);

        for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
            const segmentA = versionA[i] || 0;
            const segmentB = versionB[i] || 0;
            if (segmentA !== segmentB) {
                return segmentA - segmentB;
            }
        }
        return 0;
    });
};
