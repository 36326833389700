<template>
    <div id="firmware-table" class="firmware-table">
        <div class="d-flex justify-center">
            <v-progress-circular v-if="loading" indeterminate color="primary" size="50"/>
            <v-alert v-else-if="error.yes" type="error" dismissible>{{ error.msg }}</v-alert>
            <v-alert v-else-if="!isValidFirmwareData(firmwares)" type="warning">{{ AdminTabsConstant.WARN.NO_FIRMWARE_FOUND }}</v-alert>
        </div>

        <div v-if="isValidFirmwareData(firmwares)">
            <div class="firmware-table-container" v-on:scroll="handleFirmwareTableScroll">
                <md-table v-model="firmwares" md-sort="priority" md-sort-order="asc" md-card>
                    <Firmware-table-row
                        slot="md-table-row"
                        v-for="firmware in firmwares"
                        :key="firmware.id"
                        :firmware="firmware"
                        slot-scope="{item:firmware}"
                        @edit-firmware-config="data => doActionOnFirmwareConfig('update', data)"
                        @delete-firmware-config="data => doActionOnFirmwareConfig('delete', data)"
                    />
                </md-table>
            </div>
        </div>

        <div class="add-firmware-btn">
            <v-btn id="admin-firmware-btn-add" v-show="showMore" color="primary" fab small @click="_ => doActionOnFirmwareConfig('create')">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn id="admin-firmware-btn-apply" v-show="isValidFirmwareData(firmwares) && showMore" color="primary" fab small @click="_ => doActionOnFirmwareConfig('apply')">
                <v-icon>mdi-sync</v-icon>
            </v-btn>
            <v-btn id="admin-firmware-btn-showmore" color="primary" dark fab right large @click="showMore = !showMore">
                <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
        </div>

        <div>
            <CreateUpdateFirmwareDialog class="dialog" :data="createUpdateFirmwareDialogInfo"/>
            <DeleteConfirmationDialog v-if="isValidFirmwareData(firmwares)" class="dialog" :data="deleteConfirmDialogInfo"/>
            <ApplyFirmwaresDialog v-if="isValidFirmwareData(firmwares)" class="dialog" :data="applyFirmwaresDialogInfo"/>
        </div>
    </div>
</template>

<script>
import FirmwareTableRow from './firmware-table-row.vue';
import CreateUpdateFirmwareDialog from './create-update-firmware-dialog.vue';
import DeleteConfirmationDialog from '@/components/dialog/actionConfirmationDialog'
import ApplyFirmwaresDialog from './apply-frimwares-dialog';
import AdminTabsConstant from "@/constant/admin-tabs-constant";
import deviceService from "@/service/devices/device-service";
import firmwareService from "@/service/admin/firmware/firmware-service";
import Vue from "vue";
import lodash from 'lodash';

export default {
    name: "firmware-main",
    components: {

        FirmwareTableRow,
        CreateUpdateFirmwareDialog,
        DeleteConfirmationDialog,
        ApplyFirmwaresDialog
    },
    data: () => {
        return {
            firmwares: null,
            firmwareToUpdate: null,
            arbo: null,
            createUpdateFirmwareDialogInfo: null,
            deleteConfirmDialogInfo: null,
            applyFirmwaresDialogInfo: null,
            AdminTabsConstant,
            loading: false,
            error: {
                yes: false,
                msg: ''
            },
            showMore: false
        }
    },
    async mounted() {
        await this.getFirmwareData();
    },
    methods: {
        async getFirmwareData() {

            try {
                this.loading = true;
                this.arbo = (await Vue.http.get(`${this.$store.state.urlArboWithDevices}`)).body;

                const firmwaresKioskPolicyUpdate = (await Vue.http.get(`${this.$store.state.urlGetAdminAllFirmwaresKioskPolicyUpdate}`)).body;
                const firmwaresDeploymentStats = (await Vue.http.get(`${this.$store.state.urlGetAdminAllFirmwaresDeploymentStats}`)).body;
                let firmwares = (await Vue.http.get(`${this.$store.state.urlGetAdminAllFirmwaresWithRecalculatedPerimeter}`)).body;

                firmwares.forEach(firmware => {
                    firmware.calculatedPerimeterCount = firmwareService.calculatePerimeterCount(this.arbo, firmware.calculatedPerimeter);

                    let perimeterDevices = this.getPerimeterDevices(firmware.calculatedPerimeter);


                    let policyUpdatedDevices = this.getPolicyUpdatedDevices(firmware.firmware_build_versions, firmwaresKioskPolicyUpdate);
                    let devicesToUpdatePolicy = lodash.difference(perimeterDevices, policyUpdatedDevices);

                    const firmwareDeployedStats = this.getFirmwareDeployedStats(firmware.firmware_build_versions, firmwaresDeploymentStats);
                    let firmwareDeployedDevicesStats = this.decorateDeployedDevicesStatsWithPerimeter(firmwareDeployedStats, perimeterDevices);

                    firmware.devicesToApplyFirmware = devicesToUpdatePolicy;
                    firmware.policyUpdate = perimeterDevices.length ? Math.round((1 - devicesToUpdatePolicy.length / perimeterDevices.length) * 100) : 0;

                    firmware.deployment = perimeterDevices.length ?
                        Math.round((1 - firmwareDeployedDevicesStats.update_since?.all_time?.perimeter?.up_to_date?.length / firmwareDeployedDevicesStats.update_since?.all_time?.perimeter?.global?.length) * 100) : 0;

                    firmware.deploymentStats = firmwareDeployedDevicesStats;

                    firmware.firmware_build_versions = this.getPreProcessedFirmwareBuildVersions(firmware);
                });
                this.firmwares = firmwares;
            } catch (e) {
                this.error.yes = true;
                this.error.msg = e.message || this.AdminTabsConstant.ERROR.FIRMWARE_FETCH_DATA_ERROR;
            } finally {
                this.loading = false;
            }
        },
        getPolicyUpdatedDevices(androidFirmwareVersions, policyUpdateStats) {
            return [...new Set(androidFirmwareVersions.map(version => version.firmware_build_version)
                .flatMap(version => policyUpdateStats[version] ?? []))];
        },
        getFirmwareDeployedStats(androidFirmwareVersions, firmwareDeploymentStats) {
            return androidFirmwareVersions
                .map(version => firmwareDeploymentStats[version.firmware_build_version])
                .filter(versionStats => versionStats !== undefined)
                .reduce((accumulatedStats, currentStats) => {
                    return this.mergeFirmwareVersionDeploymentStats(accumulatedStats, currentStats);
                });
        },
        mergeFirmwareVersionDeploymentStats(firstVersionDeploymentStats, secondVersionDeploymentStats) {
            let clonedFirst = lodash.cloneDeep(firstVersionDeploymentStats);

            for (const [key, value] of Object.entries(secondVersionDeploymentStats.update_since)) {
                if (clonedFirst.update_since[key]) {
                    // Merge the 'up_to_date' arrays and remove duplicates
                    clonedFirst.update_since[key].up_to_date = Array.from(new Set([
                        ...(clonedFirst.update_since[key].up_to_date || []),
                        ...(value.up_to_date || [])
                    ]));

                    // Merge the 'global' arrays and remove duplicates
                    clonedFirst.update_since[key].global = Array.from(new Set([
                        ...(clonedFirst.update_since[key].global || []),
                        ...(value.global || [])
                    ]));
                }
            }
            return clonedFirst;
        },
        async createFirmwareConfig(newFirmware) {
            newFirmware.created_by = this.$store.state.user.uid;
            this.processFirmwareBuildVersionsToCreateOrUpdate(newFirmware);
            await this.tryCatchApiCall(Vue.http.post(`${this.$store.state.urlCreateUpdateDeleteFirmwares}`,
                {firmwares: [lodash.pick(newFirmware,["priority", "firmware_build_versions", "perimeter", "created_by"])]}));
        },
        async updateFirmwareConfig(firmwareToUpdate) {
            firmwareToUpdate.last_change_by = this.$store.state.user.uid;
            this.processFirmwareBuildVersionsToCreateOrUpdate(firmwareToUpdate);
            await this.tryCatchApiCall(Vue.http.put(`${this.$store.state.urlCreateUpdateDeleteFirmwares}`,
                {firmwares: [lodash.pick(firmwareToUpdate,["id", "priority", "firmware_build_versions", "perimeter", "last_change_by"])]}));
        },
        async deleteFirmwareConfig(firmwareToDelete) {
            await this.tryCatchApiCall(Vue.http.delete(`${this.$store.state.urlCreateUpdateDeleteFirmwares}`, {
                body: {ids: [firmwareToDelete.id]}, headers: {'Content-Type': 'application/json'}
            }));
        },
        async applyFirmwaresConfig(firmwaresToApply) {
            const kioskIdsToApplyFirmware = [...new Set(firmwaresToApply.flatMap(firmware => firmware.devicesToApplyFirmware))];

            await deviceService.disableKiosksDevModeBeforeFirmwareUpdate(kioskIdsToApplyFirmware);

            await deviceService.notifyAamKiosks(kioskIdsToApplyFirmware);
        },
        doActionOnFirmwareConfig(action, data) {
            switch (action) {
                case "create":
                case "update":
                    this.createUpdateFirmwareDialogInfo = {
                        type: action,
                        visible: true,
                        ...{firmware: action === "update" ? lodash.cloneDeep(data) :
                                {firmware_build_versions: this.getPreProcessedFirmwareBuildVersions({})}},
                        arbo: lodash.cloneDeep(this.arbo),
                        confirm: firmware => action === "update" ?
                            this.updateFirmwareConfig(firmware) : this.createFirmwareConfig(firmware),
                        closeDialog: _ => {this.createUpdateFirmwareDialogInfo.visible = false;}
                    };
                    break;
                case "delete":
                    this.deleteConfirmDialogInfo = {
                        visible: true,
                        title: 'Confirmation',
                        text: `Are you sure to delete this firmware config?`,
                        confirm: _ => this.deleteFirmwareConfig(data)
                    };
                    break;
                case "apply":
                    this.applyFirmwaresDialogInfo = {
                        visible: true,
                        firmwares: this.firmwares,
                        confirm: _ => this.applyFirmwaresConfig(this.firmwares)
                    };
                    break;
            }
        },
        async tryCatchApiCall(apiRequest) {
            try {
                this.error.yes = false;
                await apiRequest;
                await this.getFirmwareData();
            } catch (e) {
                this.error.yes = true;
                this.error.msg = e.message || e.body;
            }
        },
        decorateDeployedDevicesStatsWithPerimeter(firmwaresDeploymentStats, perimeterDevices){

            if ( ! firmwaresDeploymentStats.update_since){
                return {};
            }

            const KEYS_TO_LOOP_ON = ['one_day', 'one_week', 'one_month','all_time'];

            KEYS_TO_LOOP_ON.forEach(key => {
                firmwaresDeploymentStats.update_since[key].perimeter = {};

                // perimeterDevices
                firmwaresDeploymentStats.update_since[key].perimeter.global =
                lodash.intersection(firmwaresDeploymentStats.update_since[key].global, perimeterDevices.map( d => d + '') );

                firmwaresDeploymentStats.update_since[key].perimeter.up_to_date =
                lodash.intersection(firmwaresDeploymentStats.update_since[key].up_to_date, firmwaresDeploymentStats.update_since[key].perimeter.global);

            });

            return firmwaresDeploymentStats;

        },
        getPerimeterDevices({countries, stores, devices}) {
            let perimeterDevices = [];
            countries.forEach(country => {
                let _country = this.arbo.retail.site.find(item => item.ref === country);
                if(_country) _country.sites.forEach(store => perimeterDevices = [...perimeterDevices, ...(store.devices && store.devices.map(device => device.id))])
            });
            stores.forEach(store => {
                this.arbo.retail.site.forEach(country => {
                    let _store = country.sites.find(item => item.id === store);
                    if(_store) perimeterDevices = [...perimeterDevices, ...(_store.devices && _store.devices.map(device => device.id))];
                })
            });
            perimeterDevices = [...perimeterDevices, ...devices];
            return perimeterDevices;
        },
        isValidFirmwareData(firmwares) {
            return Array.isArray(firmwares) && firmwares.length > 0;
        },
        handleFirmwareTableScroll(event) {
            if (event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight) {
                this.showMore = false;
            }
        },
        getPreProcessedFirmwareBuildVersions(firmware) {
            if (!(firmware.firmware_build_versions?.length)) {
                firmware.firmware_build_versions = [{
                    id: Date.now(),
                    android_version: '',
                    firmware_build_version: '',
                    firmware_build_version_url: ''
                }];
            } else {
                firmware.firmware_build_versions.forEach(version => version.id = `${version.android_version}_${version.firmware_build_version}`);
            }
            return firmware.firmware_build_versions;
        },
        processFirmwareBuildVersionsToCreateOrUpdate(firmware) {
            if (firmware.firmware_build_versions) {
                firmware.firmware_build_versions = lodash.map(firmware.firmware_build_versions, version => lodash.omit(version, ['id']));
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/color';

.firmware-table-container {
    max-height: 500px;
    overflow-y: auto;
}

.firmware-table {
    margin-top : 1%;
    background-color : $grey5;
    padding: 1%;
    position: relative;
}

.add-firmware-btn {
    position: fixed;
    bottom: 20%;
    right: 10%;
    z-index: 5;
}

    .add-firmware-btn > .v-btn:nth-child(1) {
        position: absolute;
        top: 18%;
        left: -70%;
    }

    .add-firmware-btn > .v-btn:nth-child(2) {
        position: absolute;
        top: -70%;
        left: 18%;
    }

    .dialog {
        z-index: 10;
    }

</style>
