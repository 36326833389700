import parameterApiService from "@/service/admin/parameter/parameter-api-service";

export default {
    async getObjectEnvVar(objEnvVar) {
        const envVarValue = process.env[objEnvVar];

        if (!envVarValue) {
            const fetchedValue = await parameterApiService.getJsonValueBy(objEnvVar.replaceAll("_", ".").toLowerCase());
            process.env[objEnvVar] = JSON.stringify(fetchedValue);
            return fetchedValue;
        }

        return JSON.parse(envVarValue);
    }
};
