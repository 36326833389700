import Vue from "vue";
import {store} from "../../../store/store";

export default {
    async getBooleanBy(paramId, defaultValueIfFails) {
        return getValueByWithTryCatch(paramId, (response) => response?.body?.parValue === "true", defaultValueIfFails);
    },

    async getStringValueBy(paramId, defaultValueIfFails) {
        return getValueByWithTryCatch(paramId, (response) => {
            if (response?.body?.parValue) return response?.body?.parValue;
            throw "No parameter value found --> fallback to default value";
        }, defaultValueIfFails);
    },

    async getJsonValueBy(paramId, defaultValueIfFails) {
        return getValueByWithTryCatch(paramId, (response) => {
            if (response?.body?.parValue) return JSON.parse(response?.body?.parValue);
            throw "No parameter value found --> fallback to default value";
        }, defaultValueIfFails);
    }
}

const getValueByWithTryCatch = async (paramId, resultCallBack, defaultValueIfFails) => {
    try {
        const response = await getValueBy(paramId);
        return resultCallBack(response);
    } catch (e) {
        console.warn("Can't get the parameter " + paramId, e);
    }
    return defaultValueIfFails;
}

const getValueBy = async (paramId) => {
    return Vue.http.get(store.state.urlGetAdminParameter + paramId);
}
