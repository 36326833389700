<template>
    <svg width="25" height="25" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_319_2576)">
            <path d="M65.7198 30.1853C55.497 24.4675 39.0891 15.2861 13.6392 1.03607C11.9233 -0.0971069 9.94916 -0.245607 8.24347 0.331722L51.9084 43.9967L65.7198 30.1853Z" fill="#149B65" fill-opacity="0.8"/>
            <path d="M8.24348 0.331726C7.92379 0.440008 7.6127 0.570633 7.31518 0.728414C5.4316 1.74729 4.04749 3.70787 4.04749 6.18752V81.8058C4.04749 84.2854 5.43143 86.246 7.31518 87.2647C7.61218 87.4223 7.9231 87.5535 8.24245 87.6624L51.9084 43.9967L8.24348 0.331726Z" fill="#149B65" fill-opacity="0.8"/>
            <path d="M51.9084 43.9966L8.24243 87.6624C9.94864 88.2441 11.9228 88.1069 13.6391 86.9572C38.3201 73.1367 54.5431 64.0641 64.8841 58.2977C65.172 58.136 65.4541 57.9778 65.7328 57.8213L51.9084 43.9966Z" fill="#149B65" fill-opacity="0.8"/>
            <path d="M4.04749 43.9966V81.8058C4.04749 84.2854 5.43143 86.246 7.31518 87.2647C7.61218 87.4223 7.9231 87.5535 8.24245 87.6624L51.9084 43.9966H4.04749Z" fill="#149B65"/>
            <path d="M13.6392 1.03605C11.6016 -0.309391 9.19877 -0.271063 7.31519 0.728564L51.2457 44.6592L65.7196 30.1852C55.497 24.4675 39.0891 15.2861 13.6392 1.03605Z" fill="#7AFFA6"/>
            <path d="M51.246 43.3342L7.31519 87.2647C9.19894 88.2642 11.6016 88.3219 13.6392 86.9572C38.3201 73.1367 54.5431 64.0641 64.8841 58.2977C65.172 58.136 65.4541 57.9778 65.7329 57.8213L51.246 43.3342Z" fill="#7AFFA6"/>
            <path d="M83.9525 43.9966C83.9525 41.9015 82.8953 39.7871 80.8 38.6145C80.8 38.6145 76.8814 36.4281 64.8702 29.7098L50.5834 43.9966L64.8841 58.2975C76.7635 51.6276 80.7998 49.3786 80.7998 49.3786C82.8953 48.2062 83.9525 46.0918 83.9525 43.9966Z" fill="#149B65" fill-opacity="0.8"/>
            <path d="M80.8 49.3787C82.8953 48.2062 83.9525 46.0918 83.9525 43.9966H50.5834L64.8841 58.2975C76.7637 51.6277 80.8 49.3787 80.8 49.3787Z" fill="#149B65"/>
        </g>
        <defs>
            <clipPath id="clip0_319_2576">
                <rect width="88" height="88" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "green-playstore-logo"
};
</script>
