export const messageConstant = {
  DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE: "Your user rights are insufficient",
  DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_AAM: "This device is enrolled via google",
  DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_NOT_AAM: "This device is not enrolled via google",
  DEVICE_IS_NOT_KIOSK: "This device is not a kiosk",
  DEVICE_IS_KIOSK: "This device is a kiosk",
  DEVICE_IS_NOT_CONNECTED: "This device is not connected",
  GROUP_PRIO_IMPOSSIBLE: "You can't change the priority because there is 0 or 1 group or your rights are insufficient",
  GROUP_ADD_IMPOSSIBLE: "You can't add a new group because your rights are insufficient",
  GROUP_ADD_IMPOSSIBLE_MAX_LIMIT: "You can't add / modify a group because the limit is fixed to ",
  GROUP_ADD_MODIFY_IMPOSSIBLE_MAX_LIMIT: "You need to clean up your groups. The allowed limit has been exceeded : ",
  GROUP_DELETE_IMPOSSIBLE: "You can't delete this group because your rights are insufficient",
  GROUP_DELETE_PROCESSING_IMPOSSIBLE: "You can't delete this group because a calculation is in progress",
  GROUP_MODIFY_IMPOSSIBLE: "You can't modify this group because your rights are insufficient",
  GROUP_MODIFY_PROCESSING_IMPOSSIBLE: "You can't modify this group because a calculation is in progress",
  GROUP_MODIFY_NO_VERSION_AVAILABLE: "No version is available on our servers for this application",
  APPLICATION_CALC_ANDROID_POLICIES: "Force the updates on android policies",
  APPLICATION_CALC_ANDROID_POLICIES_IMPOSSIBLE: "You can't force the updates on android policies because your rights are insufficient",
  APPLICATION_EDIT_IMPOSSIBLE: "You can't edit this app because your rights are insufficient",
  APPLICATION_DELETE_IMPOSSIBLE: "You can't delete this application because there are groups or your rights are insufficient",
  VERSION_ADD_IMPOSSIBLE: "You can't add a new version because your rights are insufficient",
  VERSION_MODIFY_IMPOSSIBLE: "You can't modify this version because your rights are insufficient",
  VERSION_DOWNLOAD_IMPOSSIBLE: "You can't download this version because your rights are insufficient",
  VERSION_DELETE_IMPOSSIBLE: "You can't delete this version because it's the biggest or your rights are insufficient. If you made a mistake by uploading this version, please update an other one with a version code greater than this one",
  DEVICE_APP_NOT_INSTALLED: "Not installed",
  DEVICE_APP_INSTALLED: "Installed",
  DEVICE_APP_NOT_UP_TO_DATE : "Not up to date",
  STATUS_TODO: "TODO : The group needs to calculate the deployment of the application",
  STATUS_IN_PROGRESS: "1/2 CONFIGURATION : Deployment group is being prepared",
  STATUS_NOTIFYING: "2/2 ASSIGNMENT : Configuration is being applied",
  STATUS_DONE: "DONE : The group has correctly calculate the deployment of the application",
  STATUS_FAILED: "FAILED : An error occurred during the calculation of the application's deployment",
  FAILED_NOTIFICATION: "FAILED NOTIFICATION : An error occurred during the notification of devices, please click on android button or contact your administrator",
  RETRY_COMPLETE_PRIO: "Can't retry : There is no group or all status are DONE or there is a status IN PROGRESS",
  ERROR_EMPTY_INPUT: "Please select search criteria",
  OS_VERSION_PLACEHOLDER: "You can add OS version",
  GROUP_PRIORITY_ERROR: "We are encountering a problem to do the priority of your groups. Please, contact your administrator.",
  NO_VERSION : "----",
  NO_GROUP : "----",
  ERROR_UPDATE_CUSTOM_MODE: "An error occurred during update of the type of enrolment. Please contact your administrator.",
  EXPLICIT_AAM_MESSAGE: "Please note that most features are not available because the device concerned is enrolled via google.",
  OK_AT_NEXT_REBOOT: "Your change will be available at next reboot.",
  ERROR_DURING_APP_CHANGE: "An error occurred when trying to change default app. Contact your administrator.",
  APPLICATION_FILE_NAME_RESTRICTION: "File name restriction : file name starts with a letter and should contain only letters, numbers, hyphen, underscore and period",
  APPLICATION_ADD_BAD_EXTENSION_WITHOUT_APK: "Bad file extension : your file must be a 'aab'.",
  APPLICATION_ADD_BAD_EXTENSION_WITH_APK: "Bad file extension : your file must be a 'apk' or a 'aab'.",
  APPLICATION_ADD_BIG_SIZE: "File size is greater than 500MB. Upload is impossible.",
  VUE_APP_ALLOWED_APK_SIZE: `Apk size is greater than ${process.env.VUE_APP_ALLOWED_APK_SIZE}MB.`,
  VUE_APP_ALLOWED_AAB_SIZE: `Aab size is greater than ${process.env.VUE_APP_ALLOWED_AAB_SIZE}MB.`,
  APPLICATION_SIZE_NEAR_TO_LIMIT_WARNING: "Warning: Your app size has exceeded 90% of that is allowed: {allowedAppSize}MB !",
  APPLICATION_DESCRIPTION_UNAVAILABLE: "The description is currently unavailable. Please reload the page or try again later.",
  ERROR_REBOOT: "An error occurred during reboot of the device. Please contact your administrator.",
  FORCE_UPDATE_JUST_ACTIVATED : "You have just activated the force update on the device. This will remain active for ",
  ONE_DAY: "24 hours",
  ERROR_FORCE_UPDATE: "An error occurred, force update function is not activated.",
  NEW_CHOSEN_PASSWORD: "This is the new password you have chosen. Please don't forget it !",
  NEW_RANDOM_PASSWORD: "Your device is unlocked, this is the new password. Most of the time it's instantaneous, but it can take up to several minutes. We recommand you to change it as soon as possible.",
  PASSWORD_RULES: "For your security, your password must not contain the same 4 digits or a logical sequence (ex: 1234, 2468, 7531...)",
  CHOOSE_OTHER_PASSWORD: "Please choose an other password.",
  ERROR_DURING_CHANGE_LOCK_CODE: "An error occurred when trying to change lock code. Please contact your administrator.",
  ONLY_NUMBERS: "The lock code must only contain numbers.",
  FIRMWARE_UPDATING: `This kiosk can't be rebooted or locked for 24 hours because the firmware is updating`,
  DEPLOY_ON_SERVICES: "This action will deploy the production version of this app on devices in the perimeter \"Services\". Do you confirm ?",
  UNDEPLOY_ON_SERVICES: "This action will stop the update of this app on devices in the perimeter \"Services\". Do you confirm ?",
}

export default messageConstant;
