import Vue from "vue";
import {store} from "../store/store";
import {displayAlertDialog} from "../utils/utils";

const storeUtils = require('../utils/store-utils');

export default {

  async download(versionFilePath){

      try {
          const base64FilePath = btoa(versionFilePath);
          const apiUrlToCall = `${store.state.urlDownloadVersion}`
              .replace("{country}", store.state.user.country?.toUpperCase())
              .replace("{store}", store.state.user.store)
              .replace("{device}", "undefined")
              .replace("{base64_file_path}", base64FilePath);
          const response = await Vue.http.get(apiUrlToCall);
          window.open(response.data?.data?.apkUrl, "Download");
      } catch (error) {
          displayAlertDialog(error.body?.message || error);
      }

  },
  extractFilePaths(versions){
    let versionsFilepath = [];
    versions.forEach ( (version ) => {
      if (version.filePath) {
        versionsFilepath.push(version.filePath);
      }
    });
    return versionsFilepath;
  },

  async getAvailableInformationOnVersion(versions) {

    let versionsFilepath = this.extractFilePaths(versions);

    const objKey = "versionsAvailableS3";
    const packageName = versionsFilepath.length > 0 ? versionsFilepath[0].uid : undefined;
    if (packageName && storeUtils.keyExists(objKey, packageName)) {
      return Promise.resolve(storeUtils.get(objKey, packageName));
    }

    return new Promise ( (resolve, reject) => {

      // Get the s3 (europe / china info)
      Vue.http.post(`${store.state.urlAWSAppsAvailable}`, versionsFilepath).then((response) => {
        let availableApk = [];

        availableApk[undefined] = {
          available: false,
          detail: {
            europe: false
          }
        };

        // put result on a map
        response.body.checksS3.forEach((check) =>
          availableApk[check.filePath] = {
            available: check.available.europe,
            detail: check.available
          }
        );

        let newVersions = versions.map(
          (version) => {
            version.available = availableApk[version.filePath];
            return version;
          }
        );

        storeUtils.put(objKey, packageName, newVersions);
        resolve(newVersions);

      }, (error) => {
        console.error(error);
        reject (error);
      });

    });
  }
}
