<template>


    <div class="legend">

        <v-chip @click="search(item)"
                v-for="(item, index) in legends"
                :key="index"
                :color="'grey-lighten-3'"
                :class="'legend-chip dark ' + getActiveFilterClass(item)"
        >
            <component v-if="item.icon" :is="item.icon" class="mr-1"/>
            {{ item.text }}
        </v-chip>

    </div>
</template>

<script>
import Hourglass from "../icons/hourglass-icon.vue";
import CheckCircle from "../icons/check-circle-icon.vue";
import BinMdmStatus from "../icons/bin-mdm-status-icon.vue";
import CloseCircle from "../icons/close-circle-icon.vue";
import constantsMigration from "../constants-migration";

export default {
    name: 'Legend',
    components: {CloseCircle, BinMdmStatus, CheckCircle, Hourglass},
    data() {
        return {
            legends: [],
            statusFilter: {},
            constantsMigration
        };
    },
    mounted() {
        this.legends = [
            {
                text: constantsMigration.LEGEND.WAITING_TO_WIPE_STATUS, icon: 'hourglass',
                filter: {type: 'mdm', name: 'isNotWiped'}
            },
            {
                text: constantsMigration.WIPED_FROM_MDM, icon: 'bin-mdm-status',
                filter: {type: 'mdm', name: 'isWiped'}
            },
            {
                text: constantsMigration.LEGEND.ENROLLED_WSO_STATUS, icon: 'check-circle',
                filter: {type: 'wso', name: 'isInWso'}
            },
            {
                text: constantsMigration.LEGEND.NOT_ENROLLED_WSO_STATUS, icon: 'close-circle',
                filter: {type: 'wso', name: 'isNotInWso'}
            },
            {text: constantsMigration.LEGEND.DISPLAY_ALL}
        ]
    },
    methods: {

        hasToDisplayAll() {
            return Object.keys(this.statusFilter).every(type =>
                Object.values(this.statusFilter[type]).every(value => value === false)
            );
        },

        getActiveFilterClass(item) {
            const itemClass = 'legend-background';

            const hasToDisplayEverything = this.hasToDisplayAll();
            const isCurrentItemFocused = item?.filter
                && this.statusFilter[item.filter.type]
                && this.statusFilter[item.filter.type][item.filter.name];

            return itemClass + ((!item?.filter && hasToDisplayEverything) || isCurrentItemFocused ? '-active' : '')
        },

        resetStatusFilter() {
            this.statusFilter = {
                mdm: {
                    isNotWiped: false,
                    isWiped: false,
                },
                wso: {
                    isNotInWso: false,
                    isInWso: false
                }
            }
        },
        search(item) {
            this.resetStatusFilter();
            if (item?.filter) {
                this.statusFilter[item.filter.type][item.filter.name] = true;
            }
            this.$emit("startSearch", this.statusFilter);
        }
    }
};
</script>

<style scoped>
.legend {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    background-color: transparent;
    border-radius: 5px;
    margin-bottom: 15px;
}

.legend-chip {
    margin-bottom: 5px;
    color: black;
}

.legend-background {
    background-color: rgb(247 248 249) !important;
}

.legend-background-active {
    background-color: rgba(224, 224, 224, 0.99) !important;
    font-weight: bold;
    border-style: dotted;
    border-width: medium;
}

</style>
