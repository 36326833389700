<template>
    <div class="group-recap mdm-vtmn-default-backgroud">
        <div v-if="!$vuetify.breakpoint.xs">
            <img :src="group.application.icon.imageBase64 || group.application.icon" class='group-recap-app-icon'/>
        </div>
        <div class="group-recap-name" v-if="!$vuetify.breakpoint.xs">
            <p id="group-recap-application-name-p"><b>{{ group.application.name }}</b></p>
            <p id="group-recap-group-name-p">{{ group.name }}</p>
        </div>
        <div v-if="$vuetify.breakpoint.width > 800">
            <p id="group-recap-version-name-p">{{ group.version && group.version.version }}</p>
        </div>
        <div v-if="$vuetify.breakpoint.mdAndUp">
            <p v-for="type in group.requirement.deviceType" :key="type" :id="'group-recap-type-p-' + type">
                {{ type }}</p>
        </div>
        <div v-if="$vuetify.breakpoint.width > 800 && countUser !== null" id="group-recap-user-count-p">
            <p>{{ countUser || 0 }} {{ countUser > 1 ? 'users' : 'user' }}</p>
        </div>
        <div class="group-recap-model"
             v-if="$vuetify.breakpoint.mdAndUp && (group.includeModelCheck || group.excludeModelCheck)">
            <p id="group-recap-model-include-p" v-if="group.includeModelCheck">
                {{ group.requirement.models.included.length }} included
                {{ group.requirement.models.included.length > 1 ? 'models' : 'model' }}</p>
            <p id="group-recap-model-exclude-p" v-if="group.excludeModelCheck">
                {{ group.requirement.models.excluded.length }} excluded
                {{ group.requirement.models.excluded.length > 1 ? 'models' : 'model' }}</p>
        </div>
        <div>
            <div v-if="!canDeployApp" @mouseover="displayEmptyFieldError" class="overlay-disabled-button"/>
            <button v-if="popin !== 'deploy'" id="group-recap-btn-deploy" class="vtmn-btn" :disabled="!canDeployApp"
                    @click="deployPopin('Show')">
                Deploy
            </button>
        </div>

        <div v-if="popin != null" class="popins">
            <div class='popin-overlay' @click="deployPopin('Cancel')"></div>
            <div v-if="popin == 'deploy'" class="popin popin-wipe">

                <div class="group-alert-content-container">

                    <div class='group-alert'>
                        <div class="group-alert-warning-content">
                            <table>
                                <tr>
                                    <td class='tableWipe'>
                                        <img :src="group.application.icon.imageBase64 || group.application.icon"
                                             class='popin-wipe-icon'/>
                                    </td>
                                    <td class="tableWipeVerticalAlign">
                                        <p>WARNING : You are about to deploy the deployment group {{ group.name }}<br/>
                                            Your deployment action will impact:
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>


                        <div class="group-alert-list-container">
                            <ul class="ulWipe">
                                <li class="liWipe">The type{{ group.requirement.deviceType.length > 1 ? 's' : '' }}
                                    {{ group.requirement.deviceType.join(", ") }}
                                </li>
                                <li class="liWipe">{{ countUser || 0 }} {{ countUser > 1 ? 'users' : 'user' }}</li>
                                <li class="liWipe"
                                    v-if="group.includeModelCheck && group.requirement.models.included?.length > 0">
                                    {{ group.requirement.models.included.length }} included
                                    {{ group.requirement.models.included.length > 1 ? 'models' : 'model' }}
                                </li>
                                <li class="liWipe"
                                    v-if="group.excludeModelCheck && group.requirement.models.excluded?.length > 0">
                                    {{ group.requirement.models.excluded.length }} excluded
                                    {{ group.requirement.models.excluded.length > 1 ? 'models' : 'model' }}
                                </li>
                                <li class="liWipe group-alert-deactivation-text" v-if="!group.activated">
                                    As this group is disabled, the app will be removed from targeted devices!
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div class='popin-text'><strong>Are you sure you want to deploy this deployment group ?</strong></div>

                <div class='popin-wipe-actions popin-actions'>
                    <md-button class="md-raised" @click="deployPopin('Cancel')">No, cancel</md-button>
                    <md-button id="confirm-deploy" class="md-raised md-accent" @click="deployPopin('Confirm')">
                        <md-icon>forward_to_inbox</md-icon>
                        Yes, deploy
                    </md-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import GroupUtils from '../../../../../utils/group-utils';
import GroupService from '../../../../../service/group/group-service';

export default {
    name: 'group-recap',
    components: {},
    data: function () {
        return {
            canDeployApp: false,
            popin: null
        }
    },
    computed: {
        group() {
            return this.$store.state.group.group;
        },
        countUser() {
            return this.$store.state.group.group.countUser;
        }
    },
    watch: {
        async group() {
            await this.countTheUser();
            this.calculCanDeployGroup();

            if (this.$vuetify.breakpoint.mdAndDown && !this.group.emptyFieldShow && !this.canDeployApp) {
                this.$store.dispatch("group/setGroup", {emptyFieldShow: true});
            } else if (this.group.emptyFieldShow && this.canDeployApp) {
                this.$store.dispatch("group/setGroup", {emptyFieldShow: false});
            }
        }
    },
    methods: {
        countTheUser: function () {
            return GroupService.countUser(this.group).then((response) => {
                this.$store.dispatch("group/setCountUser", response.body.count);
            }, (error) => {
                console.warn("Count user not work.", error);
                this.$store.dispatch("group/setCountUser", null);
            });
        },
        deployPopin(fn) {
            eval("this.deployPopin" + fn + "()");
        },
        deployPopinShow() {
            this.popin = "deploy";
        },
        deployPopinConfirm() {
            this.deploy();
            this.popin = null;
        },
        deployPopinCancel() {
            this.popin = null;
        },
        deploy() {
            GroupUtils.canDeploy(this.group, this.countUser, this.$route.params.idGroup, this.$route.fullPath) && this.$store.dispatch('group/saveGroup');
        },
        displayEmptyFieldError() {
            if (!this.canDeployApp) {
                this.$store.dispatch("group/setGroup", {emptyFieldShow: true});
            }
        },
        calculCanDeployGroup() {
            this.canDeployApp = GroupUtils.canDeploy(this.group, this.countUser, this.$route.params.idGroup, this.$route.fullPath);
        }
    }
}
</script>

<style lang="scss">

.group-alert-deactivation-text {
    text-decoration: underline;
    font-weight: bolder;
}

.group-alert {
    background-color: var(--vtmn-base-color_red400);
    color: #fff;
    font-style: italic;
    padding-left: 5%;
    margin-bottom: 20px;
}

.group-alert-warning-content {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 20px;
}

.group-alert-list-container {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1; /* Pour centrer entre l’avertissement et la question */
    margin-bottom: 20px;
    margin-top: 20px;
}

.group-alert-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.group-alert-popin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.group-recap {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.25);
    max-height: 75px;
    height: 75px;
    z-index: 8;

    > div {
        padding: 0px 30px 0px 10px;
        margin: auto 0px;
        overflow-wrap: break-word;

        > div.row {
            height: 100%;
            align-content: space-evenly;
        }

        &:last-child {
            @media (max-width: 600px) {
                margin: auto;
            }
            margin-left: auto;
        }

        &.group-recap-model {
            color: var(--vtmn-color_danger);
        }

        &.group-recap-name {
            @media (max-width: 800px) {
                max-width: none;
            }
            max-width: 20%;
        }

        &#group-recap-user-count-p {
            color: var(--vtmn-color_green);
        }

        > #group-recap-btn-deploy {
            min-width: 200px;
            pointer-events: all;
        }

        > .group-recap-app-icon {
            height: 75px;
            margin: auto;
        }

        > .overlay-disabled-button {
            position: absolute;
            height: -webkit-fill-available;
            width: 200px;
            z-index: 999;
        }
    }

    p {
        margin: 0;
        height: 100%;
    }
}

.popin-wipe-icon {
    width: 75px;
    vertical-align: middle;
}

.tableWipeVerticalAlign {
    vertical-align: middle;
}

</style>
