import ApplicationService from '../service/application/application-service';


const matchUrl = (value, vm) => value?.startsWith("https");

const hasMinOneFilledContent = (value, vm) => {
    return value.some(d => d?.content.trim().length > 0 && d?.content.trim().length <= vm.maxLengthDescription);
}

const mustNotContainsBlacklistedUrl = async (url) => {
    return !new RegExp(await getBlacklistUrlRegex()).test(url);
}

const mustNotContainsRegex = (url, regex) => {
    if (!regex || regex.length === 0) return true;
    return !new RegExp(regex).test(url);
}

const getBlacklistUrlRegex = async () => {
    return await ApplicationService.blacklistApplicationUrl();
}

const matchDecathlonUid = (uid) => {
    if (!uid) {
        return false;
    }

    const regExInternalUid = new RegExp(/^(\w{2,6})([\d][\d])$/gi);
    const regExExternalUid = new RegExp(/^Z([\d][\d])(\w{2,5})$/gi);
    return regExInternalUid.test(uid.toUpperCase()) || regExExternalUid.test(uid.toUpperCase());
}

export {
    matchUrl,
    hasMinOneFilledContent,
    mustNotContainsRegex,
    mustNotContainsBlacklistedUrl,
    matchDecathlonUid
}
