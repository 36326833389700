<template>
    <div v-if='device' class="page-content">
        <router-link to="/devices">
            <div id="navigation-back" class='page-title'> < Back</div>
        </router-link>
        <v-app>
            <div class="content">
                <v-row no-gutters >
                    <v-col v-if="!$vuetify.breakpoint.mobile" md="1"/>
                    <v-col cols="12" sm="10" md="10">
                        <h1 class='page-title2'>
                            <span v-if="isPreviousNavPossible()" id="previous_arrow" class='spanNavPreviousNext' @click='navPrevious'><</span>
                            <span>{{device.name}}</span>
                            <md-icon v-if="device.ownership == 'Corporate'" class="md-size-1x" style='color:#000000'>work</md-icon>
                            <md-icon v-else class="md-size-1x" style='color:#000000'>person</md-icon>
                            <span v-if="isNextNavPossible()" id="next_arrow" class='spanNavPreviousNext' @click='navNext'>></span>
                        </h1>
                    </v-col>
                    <v-col v-if="!$vuetify.breakpoint.xs" sm="2" md="1">
                        <md-button id="devices-details-btn-delete"
                                   class="md-raised background-hide-button" @click="popin = 'delete'"
                                   :class="{'button-disable' : grant('device', 'hight', device.countries)}"><md-icon>visibility_off</md-icon> Hide</md-button>
                    </v-col>
                </v-row>

                <div v-if="forceUpdate.isError" class='forceUpdateBannerError'>
                    <md-icon style="justify-content: initial">information</md-icon>
                    <span>{{forceUpdate.errorMessage || forceUpdate.defaultErrorMsg}}</span>
                </div>

                <div v-else-if="forceUpdateJustClicked || forceUpdateAndMouseOver" class='forceUpdateBannerInfos'>
                    <md-icon style="justify-content: initial">information</md-icon>
                    <span>{{forceUpdate.bannerMessage}}{{forceUpdate.remainingTime}}.</span>
                </div>
                <div v-else class='synchro' :class="device.lastSynchroStatus" @mouseover="chipLegend=true" @mouseout="chipLegend = false">
                    <md-icon>timer</md-icon>
                    <span> Last synchronisation : </span>
                    <span> {{device.lastSynchro}} </span>
                    <chip-legend v-if='chipLegend'></chip-legend>
                </div>



                <div v-if="device.androidSilentUpdate" class="alert-message-container">
                    <span aria-hidden="true" class="vtmx-alert-fill"/><span class="alert-text">{{ explicitAamMessage }}</span>
                </div>

                <responsive-action-button>
                    <mdm-vtmn-button v-if="!isKiosk" id="devices-details-btn-change-lock-code" variant="secondary" icon="vtmx-lock-fill" @click="popin = 'changeCode'; errorLockCodeMessage = null" :is-tooltip-show="tooltipShowProps" :disabled="device.blocked || grant('device','medium', device.countries) === 'button-disable' || !allowChangeLockCode" :text-tooltip="tooltipChangeLockText">
                        CHANGE LOCK CODE
                    </mdm-vtmn-button>
                    <mdm-vtmn-button id="devices-details-btn-wipe" variant="secondary" icon="vtmx-delete-bin-fill" @click="popin = 'wipe'"  :is-tooltip-show="tooltipShowProps"
                                     :disabled="!(allowWipe && grant('device','hight' , device.countries, true) !== 'button-disable')" :text-tooltip="tooltipWipeText">
                        WIPE
                    </mdm-vtmn-button>

                    <mdm-vtmn-button v-if="!isKiosk" id="devices-details-btn-block-device" variant="secondary" icon="vtmx-close-circle-fill" @click="device.blocked ? popin = 'unblock': popin = 'block'" :is-tooltip-show="tooltipShowProps" :disabled="!getBlockButtonAccessAndTooltip().grant" :text-tooltip="getBlockButtonAccessAndTooltip().tooltip()">
                        {{ device.blocked ? 'UNBLOCK' : 'BLOCK' }}
                    </mdm-vtmn-button>
                    <mdm-vtmn-button v-if="!device.androidSilentUpdate" id="devices-details-btn-localize" variant="secondary" icon="vtmx-map-pin-fill" @click="localization"  :is-tooltip-show="tooltipShowProps" :disabled="grant('device','low', device.countries) === 'button-disable'" :text-tooltip="tooltipLocalizationText">
                        LOCALIZE
                    </mdm-vtmn-button>

                    <mdm-vtmn-button v-if="isKiosk" id="devices-details-btn-pulling" variant="secondary" icon="vtmx-arrow-left-right-fill" @click="pull(device.id)" @mouseover="forceUpdate.mouseOverButton=true" @mouseout="forceUpdate.mouseOverButton=false" :is-tooltip-show="tooltipShowProps" :disabled="!(  (device.androidSilentUpdate || (!device.androidSilentUpdate && kioskIsConnected))  && canPull() ) " :text-tooltip="tooltipKioskText" :isForceUpdate="forceUpdate.enabled">
                        FORCE UPDATE
                    </mdm-vtmn-button>
                    <mdm-vtmn-button v-if="isKiosk && !device.androidSilentUpdate && staging !== 'prod'" id="devices-details-btn-command" variant="secondary" icon="vtmx-checkbox-fill" @click="commandline"
                                     :is-tooltip-show="tooltipShowProps"
                                     :disabled="!getScreenshotCommandRebootNonAamAccessAndTooltip().enabled"
                                     :text-tooltip="getScreenshotCommandRebootNonAamAccessAndTooltip().message">
                        COMMAND
                    </mdm-vtmn-button>
                    <mdm-vtmn-button v-if="isKiosk && staging !== 'prod'" id="devices-details-btn-reboot" variant="secondary" icon="vtmx-refresh-fill" @click="reboot"
                                     :useProgressSpinner="true"
                                     :is-tooltip-show="tooltipShowProps"
                                     :disabled="device.androidSilentUpdate ? !getRebootAamAccessAndTooltip().enabled : !getScreenshotCommandRebootNonAamAccessAndTooltip().enabled"
                                     :text-tooltip="device.androidSilentUpdate ? getRebootAamAccessAndTooltip().message : getScreenshotCommandRebootNonAamAccessAndTooltip().message">
                        REBOOT
                    </mdm-vtmn-button>
                    <mdm-vtmn-button v-if="isKiosk && !device.androidSilentUpdate && staging !== 'prod'" id="devices-details-btn-screenshot" variant="secondary" icon="vtmx-camera-fill" @click="screenshot"
                                     :useProgressSpinner="true"
                                     :is-tooltip-show="tooltipShowProps"
                                     :disabled="!getScreenshotCommandRebootNonAamAccessAndTooltip().enabled"
                                     :text-tooltip="getScreenshotCommandRebootNonAamAccessAndTooltip().message">
                        SCREENSHOT
                    </mdm-vtmn-button>
                    <edit-enrolment-type-button v-if="!isKiosk" :device="device" />
                    <md-button v-if="staging !== 'prod' && $vuetify.breakpoint.xs" id="devices-details-btn-delete" class="md-raised md-accent" @click="popin = 'delete'" :class="{'button-disable' : grant('device', 'hight', device.countries)}"><md-icon>delete</md-icon> Delete</md-button>
                </responsive-action-button>


                <div v-if="policyLoadingError" class="popin-actions total-width-div spaced-div visible-div">
                    <h1 class="popin-error-text large-div ">Warning, your device is part of the silent updates but we have not been able to load the data associated with it. If the problem continues, please contact your administrator</h1>
                </div>

                <device-details
                    :device="device"
                    :is-kiosk="isKiosk"
                    :kiosk="kiosk"
                    :is-admin="isAdmin"
                />

                <div v-if='kiosk' class='column '>
                    <device-app-table :is-kiosk="isKiosk" :connected-to-realtime="kioskIsConnected"
                                      :default-app="kiosk.kioskDefaultApp" :device="device"
                    />
                </div>
            </div>
        </v-app>

        <div v-if="popin != null" class="popins">
            <div class='popin-overlay' @click='popin = null; errorCommand=""'></div>
            <div v-if="popin == 'geolocation'" class="popinGeoloc">
                <span class='popin-title'>{{device.lastSynchro}}</span>
                <div v-if='locale'><iframe  width="100%" height="500" frameborder="0" scrolling="no" :src="'https://maps.google.com/maps?q='+locale.lat+','+locale.long+'&amp;ie=UTF8&amp;&amp;output=embed'"></iframe></div>
                <p v-else>Geolocation unavailable</p>
                <div class="popinGeoloc-button"><md-button id="devices-details-popup-btn-geolocation" class="md-raised" @click="closePopinAndRate">Return</md-button></div>
            </div>
            <div v-if="popin == 'delete'" class="popin popin-delete">
                <div class='popin-text'>Are you sure you want to hide {{device.name}} from the devices list ?</div>
                <div class='popin-delete-actions popin-actions'>
                    <md-button class="md-raised" @click="popin = null">No, cancel</md-button>
                    <md-button class="md-raised background-hide-button" @click='hideDeviceFromDevicesList' id="popup-delete-yes"><md-icon>visibility_off</md-icon> Yes, hide</md-button>
                </div>
            </div>
            <div v-if="popin == 'displayPassword'" class="popin popin-delete">
                <div class='popin-text'>
                    <div class="alert-message-container popin-code">
                        <span aria-hidden="true" class="vtmx-alert-fill"/><span class="alert-text">{{ newPasswordPopinMessage() }}</span>
                    </div>
                    <span v-if="!errorLockCodeMessage" class="popin-title warn">{{password}}</span>
                </div>
                <div class='popin-delete-actions popin-actions'>
                    <md-button class="md-raised" @click="popin = null">I understood</md-button>
                </div>
            </div>
            <div v-if="popin == 'deviceBlocked'" class="popin popin-delete">
                <div class='popin-text'>
                    <span class="popin-text">Most of the time it's instantaneous, but it can take up to several minutes.</span><br/><br/>
                    <span class="popin-title">Device blocked</span>
                </div>
                <div class='popin-delete-actions popin-actions'>
                    <md-button class="md-raised" @click="popin = null">I understood</md-button>
                </div>
            </div>
            <div v-if="popin == 'wipe'" class="popin popin-wipe">
                <div class='wipeAlert'>
                    <tr>
                        <td class='tableWipe'><md-icon class="md-size-3x">warning</md-icon></td>
                        <td><p>WARNING : This will <span v-if="device.androidManagementDeviceName"> remove the device of our system and </span>erase all the internal storage data :</p></td>
                    </tr>
                    <ul class="ulWipe">
                        <li class="liWipe">Your Google account</li>
                        <li class="liWipe">System and app data and settings</li>
                        <li class="liWipe">Downloaded apps</li>
                        <li class="liWipe">Music</li>
                        <li class="liWipe">Photos</li>
                        <li class="liWipe">Other user data</li>
                    </ul>
                </div>
                <div class='popin-text'><strong>Are you sure you want to wipe this device ?</strong></div>
                <div class='popin-wipe-actions popin-actions'>
                    <md-button class="md-raised" @click="popin = null">No, cancel</md-button>
                    <md-button class="md-raised md-accent" @click="wipe"><md-icon>phonelink_erase</md-icon> Yes, wipe</md-button>
                </div>
            </div>
            <div v-if="popin == 'changeCode'" class="popin popin-changeCode">
                <h2 class='page-subTitle2'>
                    <md-icon>lock_open</md-icon>
                    <span> Change lock code </span>
                </h2>
                <div>
                    <div class="div-pin-lock">
                        <input v-focus v-model="newLock1" @keyup="nextInput(evt); checkIfDisplayError()" class='pin-lock' type="text" maxlength="1">
                        <input v-model="newLock2" @keyup="nextInput(evt); checkIfDisplayError()" class='pin-lock' type="text" maxlength="1">
                        <input v-model="newLock3" @keyup="nextInput(evt); checkIfDisplayError()" class='pin-lock' type="text" maxlength="1">
                        <input v-model="newLock4" @keyup="checkIfDisplayError" class='pin-lock' type="text" maxlength="1">
                    </div>
                    <div v-if="errorLockCodeMessageComputed" class="error-lock-code-message">{{errorLockCodeMessageComputed}}</div>
                </div>
                <div class='popin-wipe-actions popin-actions'>
                    <md-button class="md-raised" @click="resetInputs">Cancel</md-button>
                    <md-button :disabled="(this.newLock1 != '' && this.newLock2 != '' && this.newLock3 != '' && this.newLock4 != '')? false : true" class="md-raised md-primary" @click="popinAfterNewPasswordValidation()">Change</md-button>
                </div>
            </div>
            <div v-if="popin == 'block'" class="popin popin-wipe">
                <div class='error-lock-code-message'>{{errorCommand}}</div>
                <div class='popin-text'>Are you sure you want to block this devices ?</div>
                <div class='popin-text'>The device will not be able to launch any Decathlon's applications.</div>
                <div class='popin-wipe-actions popin-actions'>
                    <md-button class="md-raised" @click="popin = null; errorCommand='';">No, cancel</md-button>
                    <md-button class="md-raised md-primary" @click="blockDevices"><md-icon>phonelink_lock</md-icon> Yes, block</md-button>
                </div>
            </div>
            <div v-if="popin == 'unblock'" class="popin popin-wipe">
                <div class='popin-text'>Are you sure you want to unblock this devices ?</div>
                <div class='popin-wipe-actions popin-actions'>
                    <md-button class="md-raised" @click="popin = null">No, cancel</md-button>
                    <md-button class="md-raised md-primary" @click="unBlockDevices"><md-icon>stay_current_portrait</md-icon> Yes, unblock</md-button>
                </div>
            </div>
            <div v-if="popin == 'confirmChangeLock'" class="popin popin-wipe">
                <div class='popin-text'>Are you sure you want to change the lock code ?</div>
                <div v-if='errorLockCodeMessage' class='error-lock-code-message'>{{errorLockCodeMessage}}</div>
                <div class='popin-wipe-actions popin-actions'>
                    <md-button class="md-raised" @click="popin = null; resetInputs()">No, cancel</md-button>
                    <md-button class="md-raised md-primary" @click="sendChangeLock(newPassword)">Yes, change</md-button>
                </div>
            </div>
            <div v-if="popin == 'command'" class="popinTerminal">
                <div id="terminal"></div>
            </div>
        </div>

        <md-dialog-alert id="lockAlert"
                         :md-active.sync="deviceDetailAlert"
                         :md-content="deviceDetailMessage != ''? deviceDetailMessage:'An error occurred.'"
                         md-confirm-text="OK" />

        <screenshot-dialog :screenshot="screenshotToDisplay" :showDialog="showScreenshotDialog" @close="showScreenshotDialog=false" @refresh="screenshot"/>

        <div v-if='loading' class='overlay'></div>
        <md-progress-spinner v-if=loading class='loading' :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
    </div>



</template>

<script>
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import Moment from "moment";
import GrantUtils from "../../components/Grant/grant";
import GrantAccess from "../../components/Grant/grant-access";
import "vue-material/dist/theme/default.css";
import AndroidApiService from "../../service/devices/android-api-service";
import {MdmTerminal} from "../../utils/terminal.js";
import ScreenshotDialog from "../../components/ScreenshotDialog/screenshotDialog";
import DeviceAppTable from "../../components/devices/DeviceAppTable/deviceAppTable";
import DeviceDetails from "../../components/devices/DeviceDetails/deviceDetails";
import ResponsiveActionButton from "./action-button/responsive-action-button";
import deviceService from "../../service/devices/device-service";
import DeviceService from "@/service/devices/device-service";
import FieldConstant from "../../utils/field-constant";
import MessageConstant from "../../utils/message-constant";
import NewDeviceMposChoice from "./new/step/generic-policy-enrollment-choice";
import EditEnrolmentTypeButton from "../../../src/components/devices/DeviceDetails/editEnrolmentTypeButton";
import NewPasswordUtils from "../../utils/new-password-utils";
import RemoteFunctionsConstants from "../../constant/remote-functions-constants";

var utils = require('../../utils/utils.js');
var socketRealtime = require('../../utils/socketRealtime.js');

const getLastSynchroStatus = (lastSynchro) => {
    let lastSynchroStatusValue = Moment(lastSynchro).utc().diff(Moment().utc(), 'days', true);
    if(lastSynchroStatusValue > -1) return FieldConstant.DEVICE.SYNCHRO_STATUS.SYNC_OK;
    else if(lastSynchroStatusValue > -7) return FieldConstant.DEVICE.SYNCHRO_STATUS.SYNC_WARN;
    return FieldConstant.DEVICE.SYNCHRO_STATUS.SYNC_KO;
}

const computeDeviceInfo = (device, androidDeviceName) => {
    const realAndroidManagementDeviceName = device.androidManagementDeviceName;
    return {
        ...device,
        'lastSynchro' : Moment(device.lastCheck).utc().format('DD/MM/YYYY HH:mm'),
        'lastSynchroStatus' : getLastSynchroStatus(device.lastCheck),
        'businessUnit' : device.entity,
        'countries': [device.countryCode],
        'enrollmentDate' : Moment(device.activation).utc().format('DD/MM/YYYY'),
        'enrollmentTime' : Moment(device.activation).utc(),
        'ownership' : device.enterprise ? 'Corporate' : 'Personal',
        'type' : device.typeDevice,
        'version' : device.osVersion ,
        'lastUsedLogged' : device.user,
        'enrolledBy' : device.enrolledBy,
        'email' : device.accountGoogle,
        'wifi' : device.wlan,
        'adressIp' : device.ip,
        'macAdress' : device.mac,
        'block' : device.blocked,
        'androidManagementDeviceName': androidDeviceName,
        'fullAndroidManagementDeviceName': realAndroidManagementDeviceName
    }
}

Vue.use(Vuetify)

export default {
    name: 'DeviceDetail',
    components: {
        DeviceAppTable,
        ScreenshotDialog,
        DeviceDetails,
        ResponsiveActionButton,
        NewDeviceMposChoice,
        EditEnrolmentTypeButton
    },
    data : function(){
        return {
            GrantUtils,
            GrantForCommandRebootScreenshot: [['cp','appManager'], ['cc','appManager']],
            password: '',
            isChangeLockCodeByUser: null,
            loading:false,
            deviceDetailAlert : false,
            deviceDetailMessage : '',
            chipLegend : false,
            chipLegendInactif : false,
            popin : null,
            datesHisto : [],
            dateFilterValue : null,
            newLockCodeValidationInit : '',
            newLockCodeValidationConfirm : '',
            newLockCode : '',
            newLockCodeError : '',
            locale :'',
            activeRing : true,
            historicData : [],
            messageContent : '',
            titleContent : '',
            newLock1 : '',
            newLock2 : '',
            newLock3 : '',
            newLock4 : '',
            evt : '',
            policyLoadingError: false,
            screenshotToDisplay : '',
            showScreenshotDialog: false,
            callbackButtonScreenshot: false,
            tooltipChangeLockText : this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_AAM,
            tooltipWipeText: this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_AAM,
            tooltipLocalizationText : this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_AAM,
            tooltipKioskText : this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_AAM,
            tooltipCommandText : this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_AAM,
            tooltipRebootText : this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_AAM,
            tooltipScreenshotText: this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_AAM,
            terminal: new MdmTerminal(),
            kiosk: {
                kioskSite: "undefined",
                kioskName: "undefined",
                kioskPlace: "undefined",
                kioskDefaultApp: "undefined",
                kioskStartAt: "00:00:00",
                kioskStopAt: "00:00:00",
                idleModeInfo : {
                    mode: "Unknown",
                    message: "Can't retrieve kiosk information."
                }
            },

            countryAndStore : {},
            explicitAamMessage : MessageConstant.EXPLICIT_AAM_MESSAGE,
            forceUpdate:  {
                justClicked : false,
                enabled : false,
                mouseOverButton: false,
                bannerMessage: MessageConstant.FORCE_UPDATE_JUST_ACTIVATED,
                remainingTime: MessageConstant.ONE_DAY,
                isError: false,
                errorMessage: "",
                defaultErrorMsg: MessageConstant.ERROR_FORCE_UPDATE
            },
            errorLockCodeMessage: null,
            isKioskNoReboot24hours: false,
            staging: process.env.VUE_APP_ENV,
            errorCommand: ""
        }
    },
    sockets: {
        connect() {
        },
        getDeviceList(data){
        },
        MSG(data) {
            let message = JSON.parse(data)?.message
            message = JSON.parse(message)
            if (message.command == 'screenshot') {
                let screenshot = message.result;
                this.screenshotToDisplay = screenshot
                this.showScreenshotDialog=true
                typeof callbackButtonScreenshot === "function" && this.callbackButtonScreenshot()
                this.callbackButtonScreenshot=null

            } else if (message.command == 'runAsRoot') {
                let result = message.result
                result = decodeURIComponent(result)
                this.terminal.printData(result)
            }
        }
    },
    computed: {
        forceUpdateJustClicked() {
            return this.forceUpdate.justClicked;
        },
        forceUpdateAndMouseOver() {
            return this.forceUpdate.enabled && this.forceUpdate.mouseOverButton;
        },
        isKiosk() {
            return this.methodIsKiosk();
        },
        allowBlock : function() {
            if(this.device.androidSilentUpdate) return true;
        },
        allowWipe : function() {
            if(this.device.androidSilentUpdate) return true;
            if(this.device.ownership === 'Personal' && !this.device.androidManagementDeviceName) {
                this.tooltipWipeText = 'This device is personal';
                return false
            } else {
                this.tooltipWipeText = undefined;
                return true
            }
        },
        allowChangeLockCode: function() {
            if(this.device.androidSilentUpdate && this.device.ownership !== 'Personal' ) return true;
            else if(this.device.ownership === 'Personal' || this.device.version <= 7) {
                this.tooltipChangeLockText = this.device.ownership === 'Personal' ? 'This device is personal' :
                    'This android version is too old (< or = 7)';
                return false;
            } else {
                return true;
            }
        },
        toSendMessage : function() {
            var data = {
                "deviceId" : this.device.id.toString(),
                "title" : this.titleContent,
                "message" : this.messageContent,
                "package" : "com.decathlon.android.decathlonservices"
            }
            return data;
        },
        toSendRing : function() {
            var toRing = this.activeRing;
            var data = {
                "deviceId" : this.device.id.toString(),
                "type" : 'RING',
                "activation" : toRing,
                "package" : "com.decathlon.android.decathlonservices"
            }
            return data;
        },
        toSendBlock : function() {
            var data = {
                "deviceId" : this.device.id.toString(),
                "type" : 'DISABLE_DEVICE',
                "activation" : true,
                "package" : "com.decathlon.android.decathlonservices"
            }
            return data;
        },
        toSendUnBlock : function() {
            var data = {
                "deviceId" : this.device.id.toString(),
                "type" : 'DISABLE_DEVICE',
                "activation" : false,
                "package" : "com.decathlon.android.decathlonservices"
            }
            return data;
        },
        toSendBlockBackOffice: function() {
            const data = {
                "deviceIds" : [this.device.id],
                "block" : true
            }
            return data;
        },
        toSendUnBlockBackOffice: function() {
            const data = {
                "deviceIds" : [this.device.id],
                "block" : false
            }
            return data;
        },
        newPassword() {
            return this.newLock1 + this.newLock2 + this.newLock3 + this.newLock4
        },
        errorLockCodeMessageComputed() {
            return this.errorLockCodeMessage;
        }
    },
    watch : {
        popin : function() {
            if(this.popin == null){
                document.querySelector('body').style.overflow = 'scroll';
            }
        },
        newLockCodeValidationInit : function(){
            if(this.newLockCodeValidationInit == this.newLockCodeValidationConfirm){
                this.newLockCodeError = '';
                this.newLockCode = this.newLockCodeValidationConfirm;
            } else if(this.newLockCodeValidationConfirm.length == 4){
                this.newLockCodeError = "The codes are not the same";
            }
        },
        newLockCodeValidationConfirm : function(){
            if(this.newLockCodeValidationInit == this.newLockCodeValidationConfirm){
                this.newLockCodeError = '';
                this.newLockCode = this.newLockCodeValidationConfirm;
            } else if(this.newLockCodeValidationConfirm.length == 4){
                this.newLockCodeError = "The codes are not the same";
            }
        }
    },
    asyncComputed : {
        device : async function(){
            this.loading = true;
            return Vue.http.get(this.$store.state.getUrlWithParams(
                this.$store.state.urlDeviceId,
                {idDevice : this.$route.params.id})
            ).then(async (response) => {
                    const device = response.body;
                    if (device.typeDevice === "KIOSK") {
                        deviceService.getKiosk(this.$route.params.id).then((kiosk) => {
                            this.kiosk = kiosk;
                        });
                        if (device.androidSilentUpdate) {
                            try {
                                const response = await DeviceService.checkIfIsKioskToReboot(FieldConstant.DEVICE.NO_REBOOT_OR_LOCK_FOR_24_HOURS, device.id);
                                if(Boolean(response.body.valueExistsInCache)) this.isKioskNoReboot24hours = true;
                            } catch (e) {
                                this.isKioskNoReboot24hours = true;
                                console.error(e);
                            }
                        }
                    }

                    const androidDeviceName = DeviceService.formatAndroidDeviceName(device.androidManagementDeviceName);
                    if (androidDeviceName !== "") {
                        return this.loadAamDeviceInfo(androidDeviceName, device).then(aamDeviceInfo => {
                            this.policyLoadingError = false;
                            this.loading = false;
                            return {
                                ...computeDeviceInfo(device, androidDeviceName),
                                ...aamDeviceInfo
                            }
                        }).catch((e) => {
                            this.loading = false;
                            console.error("Can't load aamDeviceInfo", e);
                            this.policyLoadingError = true;
                            return computeDeviceInfo(device, androidDeviceName)
                        });

                    } else {
                        this.loading = false;
                        return computeDeviceInfo(device, androidDeviceName);
                    }
                }, (error) => {
                    this.loading = false;
                    if (!error.status) return;
                    if (error.status === 401){
                        throw new Error(error);
                    } else if (error.status !== 200){
                        this.$router.push('/devices');
                    }
                }
                )
        },
        kioskIsConnected: function() { // this method is calling when there is an update on data object
            // disable kiosk button if device is not connected to the realtime
            if (this.methodIsKiosk()) {
                let deviceId = this.$route.params.id;
                let promise = socketRealtime.verifyDeviceIsConnected(this.$store, deviceId);

                return promise.then(isConnected => {
                    if (!isConnected) {
                        this.tooltipKioskText = this.$store.state.labels.DEVICE_IS_NOT_CONNECTED;
                        this.tooltipCommandText = this.$store.state.labels.DEVICE_IS_NOT_CONNECTED;
                        this.tooltipRebootText = this.$store.state.labels.DEVICE_IS_NOT_CONNECTED;
                        this.tooltipScreenshotText = this.$store.state.labels.DEVICE_IS_NOT_CONNECTED;
                    }

                    return isConnected;
                })
            }
            return new Promise((resolve, reject) => {
                resolve(false);
            })
        }
    },
    methods : {

        getBlockButtonAccessAndTooltip: function(){

            const grantByType = this.allowBlock;
            const grantByUserRights = this.grant('device','hight' , this.device.countries) !== 'button-disable';
            const grantGlobal = grantByType && grantByUserRights;

            return {
                grant: grantGlobal,
                tooltip: () => {
                    if (this.device.androidSilentUpdate){
                        return MessageConstant.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE;
                    }
                }
            }

        },

        getRebootAamAccessAndTooltip: function(){
            return GrantAccess
                .grantAccess({
                        user: this.$store.state,
                        device: this.device
                    },
                    [['cp','appManager'], ['cc','appManager']],

                    {
                        androidSilentUpdate : true,
                        deviceType: 'KIOSK'
                    },
                    this.kioskIsConnected,
                    undefined,

                    {
                        forbidden: this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE,
                        androidSilentUpdate: this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_AAM,
                        deviceConnected: this.$store.state.labels.DEVICE_IS_NOT_CONNECTED
                    },
                    this.isKioskNoReboot24hours
                )
        },


        getScreenshotCommandRebootNonAamAccessAndTooltip: function(){
            return GrantAccess
                .grantAccess({
                    user: this.$store.state,
                    device: this.device
                },
                    [['cp','appManager'], ['cc','appManager']],

                    {
                        androidSilentUpdate : false,
                        deviceType: 'KIOSK'
                    },
                    this.kioskIsConnected,
                    true,

                    {
                        forbidden: this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE,
                        androidSilentUpdate: this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_AAM,
                        deviceConnected: this.$store.state.labels.DEVICE_IS_NOT_CONNECTED
                    }
                )
        },

        checkIfDisplayError() {
            if(this.newLock1 === '' || this.newLock2 === '' || this.newLock3 === '' || this.newLock4 === '') this.errorLockCodeMessage = null
        },
        async loadAamDeviceInfo(androidDeviceName, device) {
            return AndroidApiService.getDevicePolicy(androidDeviceName).then((policy) => {
                if(policy == null) return {};
                this.forceUpdate.enabled = policy.existInCache;
                this.forceUpdate.remainingTime = this.forceUpdate.enabled ? policy.remainingForceUpdateTime : MessageConstant.ONE_DAY;
                const deviceInfo   = policy.deviceInfo   || {};
                const androidInfo  = deviceInfo.android  || {};
                const hardwareInfo = deviceInfo.hardware || {};
                const networkInfo  = deviceInfo.network  || {};
                return {
                    enrollmentDate: Moment(deviceInfo.enrollmentTime || device.activation).utc().format('DD/MM/YYYY'),
                    enrollmentTime: Moment(deviceInfo.enrollmentTime || device.activation).utc(),
                    lastSynchro: Moment(deviceInfo.lastPolicySyncTime || device.lastCheck).utc().format('DD/MM/YYYY HH:mm'),
                    lastSynchroStatus : getLastSynchroStatus(deviceInfo.lastPolicySyncTime || device.lastCheck),
                    lastStatusReportTime: Moment(deviceInfo.lastStatusReportTime).utc().format('DD/MM/YYYY HH:mm'),
                    errorAamDeviceNotFound: policy.detail ? policy.detail : null,
                    os: "ANDROID",
                    version: androidInfo.version || device.osVersion,
                    buildVersion: androidInfo.buildVersion || device.buildVersion,

                    manufacturer: hardwareInfo.manufacturer || device.manufacturer,
                    model: hardwareInfo.model || device.model,
                    serialNumber: hardwareInfo.serialNumber || device.serialNumber,
                    imei: hardwareInfo.imei || device.imei,

                    macAdress: networkInfo.mac || device.mac,

                    androidSilentUpdate: true,
                    androidManagementMode: deviceInfo.managementMode || device.androidManagementMode
                }
            });
        },
        methodIsKiosk(){
            return utils.isKiosk(this.device);

        },
        isAdmin() {
            let isAdmin = this.$store.state.user.admin;
            if (!isAdmin) {
                this.tooltipChangeLockText = this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE;
                this.tooltipCommandText = this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE;
                this.tooltipWipeText = this.$store.state.labels.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE;
            }
            return isAdmin;
        },
        canPull(){
            return this.$store.state.user?.cc === true || this.$store.state.user?.cp === true || this.$store.state.user?.appManager === true || this.$store.state.user?.admin === true;
        },
        async pull(idDevice){
            if(this.device.androidSilentUpdate && this.isKiosk) {
                this.forceUpdate.justClicked = true;
                setTimeout(() => {
                    this.forceUpdate.justClicked = false;
                }, 30000);
                if(!this.forceUpdate.enabled) {
                    try {
                        const response = await deviceService.forceUpdateDevice(idDevice, this.device);
                        this.forceUpdate.enabled = response.body.existInCache;
                    } catch(e) {
                        console.error(e);
                        this.forceUpdate.isError = true;
                        this.forceUpdate.errorMessage = e.body?.error?.msg || e.message;
                    }

                }
            } else {
                socketRealtime.sendSocketMsg(this.$socket, this.device.id, "forceUpdate", "");
            }

        },
        closePopinAndRate(){
            this.popin = null;
            document.getElementById('feedback').click();
        },
        commandline : function () {
            let _this=this;

            function terminalDoAction(data) {
                if(data === "clear") {
                    _this.terminal.clear()
                } else {
                    socketRealtime.sendSocketMsg(_this.$socket, _this.device.id, "runAsRoot", data);
                }
            }
            this.popin = 'command';

            setTimeout(function(){
                _this.terminal.runTerminal('Connecting on ' + _this.device.name, terminalDoAction);
            }, 250);
        },
        reboot: function () {
            if(this.device.androidSilentUpdate){
                AndroidApiService.rebootAamDevice(this.getPayloadForBlockUnblockChangeLockCode()).then((response) => {
                    utils.displayAlertDialog("Device is now rebooted");
                }, (error) => {
                    console.log(error);
                    this.deviceDetailAlert = true;
                    this.deviceDetailMessage = (error.body) ? error.body.error.message : MessageConstant.ERROR_REBOOT;
                });
            }else{
                this.sendRealTimeCommand(RemoteFunctionsConstants.REBOOT_COMMAND);
            }
        },
        sendRealTimeCommand: function(command, callback){
            socketRealtime.sendSocketMsg(this.$socket, this.device.id , RemoteFunctionsConstants.RUN_AS_ROOT, command, (res) => {
                if(callback) callback(res);
                if (res.info) {
                    let action;
                    if(command === RemoteFunctionsConstants.WIPE_COMMAND) {
                        action = RemoteFunctionsConstants.WIPE_UPPERCASE;
                    } else if (command === RemoteFunctionsConstants.REBOOT_COMMAND) {
                        action = RemoteFunctionsConstants.REBOOT_UPPERCASE;
                    }
                    utils.displayAlertDialog(`Your ${action} command has been sent. Please wait 5 minutes and try again if it didn't work`);
                }
            });
        },
        screenshot: function (traitmentFinish) {
            this.callbackButtonScreenshot = traitmentFinish
            socketRealtime.sendSocketMsg(this.$socket, this.device.id , "screenshot", "" );
        },
        localization : function(){
            return Vue.http.get(
                this.$store.state.getUrlWithParams(
                    this.$store.state.urlDeviceLocalization,
                    {
                        idDevice : this.$route.params.id
                    })).then((response) =>{
                if(response.data.data){
                    var location = {
                        'merge' : response.data.data.replace(' ', ''),
                        'lat' : response.data.data.substring(0, response.data.data.indexOf('- ')),
                        'long' : response.data.data.substring(response.data.data.indexOf('- ') + 2),
                    };
                    this.popin = 'geolocation';
                    this.locale = location;
                }
                else{
                    this.popin = 'geolocation';
                }
            });
        },
        navPrevious : function(){
            this.forceUpdate.justClicked = false;
            this.isKioskNoReboot24hours = false;
            var devicesList = sessionStorage.getItem(FieldConstant.DEVICE.SEARCH.ARROW_SESSION_STORAGE_KEY);
            var array = devicesList.split(',');
            var previous = array.indexOf(this.device.id.toString());
            window.location.hash = "#/devices/"+array[previous-1];
        },
        navNext : function(){
            this.forceUpdate.justClicked = false;
            this.isKioskNoReboot24hours = false;
            var devicesList = sessionStorage.getItem(FieldConstant.DEVICE.SEARCH.ARROW_SESSION_STORAGE_KEY);
            var array = devicesList.split(',');
            var next = array.indexOf(this.device.id.toString());
            window.location.hash = "#/devices/"+array[next+1];
        },
        isPreviousNavPossible: function(){
            const devicesList = sessionStorage.getItem(FieldConstant.DEVICE.SEARCH.ARROW_SESSION_STORAGE_KEY);
            if(devicesList === null){
                return false;
            }
            var array = devicesList.split(',');
            if(array[0] == window.location.href.substr(window.location.href.lastIndexOf('/') + 1)){
                return false;
            }
            return true;
        },
        isNextNavPossible: function(){
            const devicesList = sessionStorage.getItem(FieldConstant.DEVICE.SEARCH.ARROW_SESSION_STORAGE_KEY);
            if(devicesList === null){
                return false;
            }
            var array = devicesList.split(',');
            if(array[array.length-1] == window.location.href.substr(window.location.href.lastIndexOf('/') + 1)){
                return false;
            }
            return true;
        },
        dateSelected : function(value){
            this[value.id + 'Value'] = value.value;
        },
        grant : function(category, risk, countries, isWipe){
            return GrantUtils.grant(this.$store.state,
                {
                    category,
                    risk,
                    isWipe,
                    countries
                }
            )
        },
        hideDeviceFromDevicesList : function(){
            return Vue.http.post(`${this.$store.state.urlDeviceDeactivate}`, [this.device.id]).then((response) => {
                this.$router.push('/devices')
            }, (error) => {
                this.popin = null;
                console.log(error);
            });
        },
        sendChangeLock : async function(newPassword){
            this.isChangeLockCodeByUser = true;
            this.password = newPassword;
            this.resetInputs();
            if(this.device.androidSilentUpdate) {
                try {
                    await AndroidApiService.changeLockCode(this.getPayloadForBlockUnblockChangeLockCode(), newPassword);
                } catch(e) {
                    this.errorLockCodeMessage = MessageConstant.ERROR_DURING_CHANGE_LOCK_CODE;
                    console.error(e);
                }
                this.popin='displayPassword';
            }
            else {
                try {
                    let jsonData = JSON.stringify(this.toSendChangeLock(newPassword));
                    await Vue.http.post(this.$store.state.getUrlWithParams(`${this.$store.state.urlDsRemoteFunctions}`,
                        {action: FieldConstant.REMOTE_FUNCTIONS.CHANGE_LOCK_CODE}), jsonData);
                    this.closePopinAndRate();
                } catch (error) {
                    this.manageRemoteFunctionsError(error);
                }
            }
        },
        blockAamDevice: async function(){
            try{
                await AndroidApiService.blockAamDevice(this.getPayloadForBlockUnblockChangeLockCode());
                this.popin = 'deviceBlocked';
                this.device.blocked = true;
            }catch(error) {
                this.manageRemoteFunctionsError(error);
            }
        },
        unblockAamDevice: async function(){
            try{
                const response = await AndroidApiService.unblockAamDevice(this.getPayloadForBlockUnblockChangeLockCode());
                this.isChangeLockCodeByUser = false;
                this.popin = 'displayPassword';
                this.password = response.data.password;
                this.device.blocked = false;
            }catch(error) {
                this.manageRemoteFunctionsError(error);
            }
        },
        getPayloadForBlockUnblockChangeLockCode() {
            return {
                deviceName: this.device.androidManagementDeviceName,
                deviceId: this.device.id,
                serialNumber: this.device.serialNumber,
                deviceType: this.device.type
            }
        },
        manageRemoteFunctionsError : function(error){
            this.deviceDetailAlert = true;
            this.deviceDetailMessage = error.statusText ? `An error occurred with message : ${error.statusText}` : 'An error occurred';
            console.log(error);
        },
        blockDevices : async function(){
            try {
                if(this.device.androidSilentUpdate){
                    await this.blockAamDevice();
                }else{
                    await this.blockOtherDevice();
                }
                await DeviceService.updateDevice(this.$route.params.id, this.device.blocked);
            } catch (e) {
                this.errorCommand = e.body.error.msg || e.message;
            }
        },
        blockOtherDevice: async function(){
            let jsonData = JSON.stringify(this.toSendBlock);
            let jsonData2 = JSON.stringify(this.toSendBlockBackOffice);
            this.resetInputs();
            try{
                await Promise.all([Vue.http.post(this.$store.state.getUrlWithParams(`${this.$store.state.urlDsRemoteFunctions}`,
                    {action: FieldConstant.REMOTE_FUNCTIONS.BLOCK}), jsonData),
                    Vue.http.post(`${this.$store.state.urlDeviceBlock}`, jsonData2)]);
                this.device.blocked = true;
                this.popin = null;
            }catch(error) {
                this.manageRemoteFunctionsError(error);
            }
        },
        unblockOtherDevice: async function(){
            let jsonData = JSON.stringify(this.toSendUnBlock);
            let jsonData2 = JSON.stringify(this.toSendUnBlockBackOffice);
            this.resetInputs();
            try{
                await Promise.all([Vue.http.post(this.$store.state.getUrlWithParams(`${this.$store.state.urlDsRemoteFunctions}`,
                    {action: FieldConstant.REMOTE_FUNCTIONS.BLOCK}), jsonData),
                    Vue.http.post(`${this.$store.state.urlDeviceBlock}`, jsonData2)]);
                this.device.blocked = false;
                this.popin = null;
            }catch(error) {
                this.manageRemoteFunctionsError(error);
            }
        },
        unBlockDevices : async function(){
            try {
                if(this.device.androidSilentUpdate){
                    await this.unblockAamDevice();
                }else{
                    await this.unblockOtherDevice();
                }
                await DeviceService.updateDevice(this.$route.params.id, this.device.blocked);
            } catch (e) {
                this.errorCommand = e.body.error.msg || e.message;
            }
        },
        async sendMessage(){
            try {
                let jsonData = JSON.stringify(this.toSendMessage);
                this.resetInputs();
                await Vue.http.post(this.$store.state.getUrlWithParams(`${this.$store.state.urlDsRemoteFunctions}`,
                    {action: FieldConstant.REMOTE_FUNCTIONS.SEND_A_MESSAGE}), jsonData);
                this.closePopinAndRate();
            } catch (error) {
                this.manageRemoteFunctionsError(error);
            }
        },
        async ring() {
            try {
                let jsonData = JSON.stringify(this.toSendRing);
                await Vue.http.post(this.$store.state.getUrlWithParams(`${this.$store.state.urlDsRemoteFunctions}`,
                    {action: FieldConstant.REMOTE_FUNCTIONS.RING}), jsonData);

                this.activeRing = this.activeRing !== true;

                this.closePopinAndRate();
            } catch (error) {
                this.manageRemoteFunctionsError(error);
            }
        },
        wipeClassic : function(){
            this.popin = null;
            this.sendRealTimeCommand(RemoteFunctionsConstants.WIPE_COMMAND, res => {
                if(res.info) {
                    this.hideDeviceFromDevicesList();
                } else {
                    this.deviceDetailAlert = true;
                    this.deviceDetailMessage = res.error.msg;
                }
            });
        },
        wipeAndroidApiManagement : function(){
            this.popin = null;
            deviceService.delete(this.device.id)
                .then(response => {
                    this.closePopinAndRate();
                    this.$router.push('/devices')
                }).catch((error) => {
                    this.deviceDetailAlert = true;
                    this.deviceDetailMessage = error.body.error.msg;
            });
        },
        wipe: function () {
            if (this.device.androidManagementDeviceName) {
                this.wipeAndroidApiManagement()
            } else {
                this.wipeClassic();
            }
        },
        resetInputs: function () {
            this.titleContent = '';
            this.messageContent = '';
            this.newLock1 = '';
            this.newLock2 = '';
            this.newLock3 = '';
            this.newLock4 = '';
            this.popin = null;
            this.errorLockCodeMessage = null;
        },
        nextInput: function(evt) {
            evt = (evt) ? evt : window.event;
            if (evt.target.value == '0' || evt.target.value == '1' || evt.target.value == '2' || evt.target.value == '3' || evt.target.value == '4' || evt.target.value == '5' || evt.target.value == '6' || evt.target.value == '7' || evt.target.value == '8' || evt.target.value == '9') {
                evt.target.nextElementSibling.focus();
                evt.preventDefault();
            } else {
                evt.target.value = '';
            }
        },
        toSendChangeLock : function(newPassword) {
            var data = {
                "deviceId" : this.device.id.toString(),
                "type" : 'CHANGE_PASSWORD',
                "newPassword" : newPassword,
                "package" : "com.decathlon.android.decathlonservices"
            }
            return data;
        },
        tooltipShowProps(props) {
            return props.disabled;
        },
        popinAfterNewPasswordValidation() {
            this.errorLockCodeMessage = null;
            if(isNaN(this.newLock1) || isNaN(this.newLock2) || isNaN(this.newLock3) || isNaN(this.newLock4)) {
                this.errorLockCodeMessage = MessageConstant.ONLY_NUMBERS;
                return
            }
            const messageOrPopinToDisplay = NewPasswordUtils.choosePopinAfterNewPasswordValidation(this.newLock1, this.newLock2, this.newLock3);
            messageOrPopinToDisplay === MessageConstant.PASSWORD_RULES ? this.errorLockCodeMessage = messageOrPopinToDisplay : this.popin = messageOrPopinToDisplay;
        },
        newPasswordPopinMessage() {
            return this.errorLockCodeMessage ? this.errorLockCodeMessage : NewPasswordUtils.chooseNewPasswordPopinMessage(this.isChangeLockCodeByUser);
        }
    },
}

</script>

<style lang="scss">
@import './details.scss'
</style>

<style lang="scss" scoped>
@import '~@/assets/style/color';

.background-hide-button{
        background-color : var(--vtmn-color_orange-light-2) !important;
}

.chip-legend{
    display: block !important;
    left: 150px;
    top: -10px !important;
    line-height: 10px;
}
.vtmx-alert-fill {
    color: var(--vtmn-color_warning);
    font-size: 25px;
    width: 40px;
    display:inline;
    margin-right: 15px;
    margin-top: 5px;
}

.alert-text {
    color: var(--vtmn-color_warning);
}

.alert-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    background-color: $grey5;
    width: 100%;
    &.popin-code {
        background-color:initial;
        margin-bottom: 15px;
    }
}

.error-lock-code-message {
    color: var(--vtmn-color_danger);
    text-align: center;
    margin-bottom: 15px;
}

</style>

