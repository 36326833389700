<template>
    <div id="migration-main">
        <div class="sidebar">
            <div class="sidebar-buttons">
                <div class="sidebar-icon-items">
                    <button
                        @click="showComponent('DeviceList')"
                        :class="['migration-tab-btn', { 'active': currentComponent === 'DeviceList' }]"
                    >
                        <Eye :fill-color="eyeColorComputed"/>Tracking
                    </button>
                    <button
                        @click="showComponent('DeviceMetric')"
                        :class="['migration-tab-btn', { 'active': currentComponent === 'DeviceMetric' }]"
                    >
                        <Chart :fill-color="chartColorComputed"/>Metrics
                    </button>
                </div>
            </div>
        </div>
        <div class="content">
            <component :is="currentComponent" />
        </div>
    </div>
</template>

<script>
import DeviceList from './device-list/device-list.vue';
import DeviceMetric from './device-metric/device-metric.vue';
import Eye from "./icons/eye-icon.vue";
import Chart from "./icons/chart-icon.vue";
import ConstantsMigration from "./constants-migration";

export default {
    name: 'Main',
    components: {
        Eye,
        Chart,
        DeviceList,
        DeviceMetric
    },
    data() {
        return {
            currentComponent: 'DeviceList',
            eyeColor: ConstantsMigration.COLORS.BLUE_BRAND,
            chartColor: ConstantsMigration.COLORS.BLACK_NEUTRAL
        };
    },
    computed: {
        eyeColorComputed() {
            return this.eyeColor;
        },
        chartColorComputed() {
            return this.chartColor;
        }
    },
    methods: {
        showComponent(component) {
            this.currentComponent = component;

            if(this.currentComponent === 'DeviceList') {
                this.eyeColor = ConstantsMigration.COLORS.BLUE_BRAND;
                this.chartColor = ConstantsMigration.COLORS.BLACK_NEUTRAL;
            } else {
                this.eyeColor = ConstantsMigration.COLORS.BLACK_NEUTRAL;
                this.chartColor = ConstantsMigration.COLORS.BLUE_BRAND;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/color';

#migration-main {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.sidebar {
    width: 100%;
    height: 60px;
    background-color: $grey2;
    color: #2c3e50;
    transition: height 0.3s, transform 0.3s;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sidebar-overlay {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transform: translateY(0);
    z-index: 10;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.sidebar-buttons {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #ecf0f1;
}

.migration-tab-btn {
    border: none;
    text-align: center;
    margin: 10px 0;
    padding: 10px 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    outline: none;
    display: flex;
    align-items: center;
    background-color: var(--vtmn-color_white);
}


.icon-btn {
    background: none;
    border: none;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
}

.active {
    background-color: #E1E3F5;
    color: #3643BA;
}

.sidebar-icon-items {
    display: flex;
    align-items: center;
}

.sidebar-icons {
    display: flex;
    align-items: center;
}

.content {
    flex: 1;
    background-color: #ecf0f1;
    color: #2c3e50;
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
}

.v-icon.v-icon {
    color: var(--vtmn-color_black);
}

@media screen and (max-width: 1200px) {
    #migration-main {
        width: 90%;
    }
}

</style>
