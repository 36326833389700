<template>
	<div v-if="paginate" class='pagination'>
		<span class='pagination-summary'>
			<span> Results </span>
			<span> {{isStatusFilter ? 1 : getResultsMin()}} </span>
			<span> - </span>
			<span> {{getResultsMax()}} </span>
		</span>
		<span class='pagination-items'>
			<span
				class='pagination-item pagination-action'
				@click='goFirstPage'
				:class="{'pagination-action-show' : currentPage > 2}" >
				<< </span>
			<span
				class='pagination-item pagination-action'
				@click='goPrevPage'
				:class="{'pagination-action-show' : currentPage > 1}">
				< </span>
			<span
				class='pagination-item'
				:class="{'pagination-item-active' : currentPage == page}"
				@click="goPage(page)"
				v-for='page in pages'>
				{{page + " "}}
			</span>
			<span
				:class="{'pagination-action-show' : currentPage < totalPages}"
				@click='goNextPage'
				class='pagination-item pagination-action'>
				></span>
			<span
				:class="{'pagination-action-show' : currentPage < totalPages -1}"
				@click='goLastPage'
				class='pagination-item pagination-action'
				id='last-page-button'>
				>> </span>
		</span>
	</div>
</template>

<script>
	export default{
		data : function(){
			return {
				totalPages : 0,
				newCurrentPage : this.currentPage
			}
		},
		props : ['nbOfElems', 'elemsTotal', 'currentPage', 'pageSize', 'isStatusFilter'],
		computed: {
			pages : function(){
                if(this.isStatusFilter) this.newCurrentPage = 1;
				let pagesArr = [];
				this.totalPages = Math.ceil(this.elemsTotal / this.pageSize);
				if(this.totalPages > 10){
					if(this.currentPage < 6){
						for(var i = 1; i <= 10; i++){
							pagesArr.push(i);
						}
					} else if(this.currentPage > this.totalPages - 5){
						for(var i = this.totalPages - 9; i <= this.totalPages; i++){
							pagesArr.push(i);
						}
					} else {
						for(var i = this.currentPage - 5; i <= this.currentPage + 5; i++){
							pagesArr.push(i);
						}
					}
				} else {
					for(let i = 1; i <= this.totalPages; i++){
						pagesArr.push(i);
					}
				}
				return pagesArr;
			},

		},
		methods : {
		    getResultsMin : function() {
                return this.nbOfElems > 0 ? this.pageSize * (this.currentPage - 1) + 1 : 0
            },
            getResultsMax : function() {
                return this.nbOfElems < this.elemsTotal ?
                    this.nbOfElems === this.pageSize ? this.nbOfElems * this.currentPage : this.pageSize * (this.currentPage - 1) + this.nbOfElems
                    : this.elemsTotal;
            },
            paginate : function(page){
                this.newCurrentPage = page;
                this.$emit('changePage', this.newCurrentPage);
                return true;
            },
			goPage : function(page){
                this.paginate(page);
			},
			goFirstPage : function(){
                this.paginate(1);
			},
			goLastPage : function(){
                this.paginate(this.totalPages);
			},
			goPrevPage : function(){
                this.paginate(--this.newCurrentPage);
			},
			goNextPage : function(){
                this.paginate(++this.newCurrentPage);
			},
		}
	}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/color';

.pagination{
	font-family : 'Roboto Condensed';
	color : $dark;
	font-size : 12px;
	margin-top : 5px;
    margin-bottom: 30px;
    margin-left: 15px;
    user-select : none;
	cursor : pointer;
}
.pagination-summary{
	margin-right : 38px;
}
.pagination-item{
	width : 18px;
	height : 20px;
	text-align : center;
	margin : 0px 3px;
	display : inline-block;
	line-height : 20px;
	vertical-align : middle;
}
.pagination-item-active{
	background-color : #3643BA;
	color : #fff;
    width : 24px;
}
.pagination-action{
	opacity : 0;
}
.pagination-action-show{
	opacity : 1;
}

</style>
