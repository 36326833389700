import Vue from "vue";
import {store} from "../../store/store";
import appTypeEnum from "./app-type-enum";
import UrlLogoUtils from "../../utils/url-logo-utils";
import FieldConstant from "../../utils/field-constant";
import HttpRequestsUtils from "../../utils/http-requests-utils";
import UrlConstant from '../../constant/url-constant';
import ParameterService from '../../service/admin/parameter/parameter-api-service';


import {findBestDescriptionToShow} from '../../utils/app-description-utils'

export default {

    async getWebviewInformation(packageName) {
        const urlGetWebviewInfo = store.state.getUrlWithParams(store.state.urlGetWebviewInfo, {packageName});
        const response = await Vue.http.get(urlGetWebviewInfo);
        return response.body;
    },

    async getAllApplicationsByPackageName(packageNames) {
        const response = await Vue.http.post(store.state.urlApplicationByPackageName, {packageNames});
        return response.body;
    },

    async getApplicationIcon(appId) {
        const response = await Vue.http.get(store.state.getUrlWithParams(store.state.urlApplicationIcon, {appId}));
        return response.body;
    },

    async getApplicationIconsByPackageNames(packageNamesPayload) {
        const response = await Vue.http.post(store.state.urlApplicationIconsByPackageNames, packageNamesPayload);
        return response.body;
    },
    async getAllApplicationByDeviceType(deviceType) {
        const response = await Vue.http.get(store.state.getUrlWithParams(store.state.urlApplicationsByDeviceType, {deviceType}));
        return response.body;
    },
    async getAllApplications() {
        const response = await Vue.http.post(store.state.urlApplications, {packageNames: []});
        const applications = response.body;
        let filteredApplications = [];
        for (let application of applications) {
            let userCountries = [];
            if (application.user_countries) {
                userCountries = application.user_countries.replace('[', '').replace(']', '').split(', ');
            }
            filteredApplications.push({
                id: application.id,
                name: application.name,
                grade: 0.0,
                os: application.plateform ? application.plateform.toLowerCase() : null,
                manageBy: application.manager,
                applicationResponsible: application.application_responsible,
                userCountries: userCountries,
                uid: application.uid,
                appType: application.app_type,
                playstoreReady: application.playstore_ready
            });
        }
        return filteredApplications;
    },
    async searchWebviews(packageNames = [], safe = true) {
        return (await Vue.http.post(store.state.urlWebviewsSearch, {packageNames, safe}))?.body;
    },
    async convertAppToPrintableApp(app, versionsArray = [], playstoreEventStatus = null) {

        let userCountries = [];
        if (app.userCountries && typeof app.userCountries === 'string') {
            userCountries = app.userCountries.replace('[', '').replace(']', '').split(', ');
        } else if (app.userCountries && Array.isArray(app.userCountries)) {
            userCountries = app.userCountries;
        }

        const logo = await UrlLogoUtils.getApplicationLogo(app);
        let webviewUrl = undefined;

        try {
            webviewUrl = app.appType === appTypeEnum.WEBVIEW ? (await this.getWebviewInformation(app.uid))?.startUrl : undefined;
        } catch (e) {
            if (e.status === 404) {
                console.warn("Webview " + app.uid + " not existing on Google anymore !")
                webviewUrl = 'ERROR';
            } else {
                console.error("Error on getting webview " + app.uid + " from Google Api !");
                throw e;
            }
        }

        return {
            ...app,
            name: app.name,
            logo: app.logo ? app.logo : null,
            uid: app.uid,
            review: {
                grade: 0,
                nb: 0
            },
            icon: logo.icon,
            url: webviewUrl,
            platform: app.platform,
            manageBy: app.managerUser,
            responsible: app.applicationResponsible,
            playstoreReady: app.playstoreReady,
            description: app.applicationDescription,
            versions: versionsArray,
            userCountries: userCountries,
            playstoreEventStatus: playstoreEventStatus,
        };
    },
    async getApplication(idOrPackageName) {
        return Vue.http.get(store.state.getUrlWithParams(store.state.urlApplicationIdOrPackageName, {idOrPackageName}))
            .then(response => {
                if (response.status !== 200) return null;
                return response.body;
            }).catch(e => {
                console.error(e);
                return null;
            });
    },

    async getAllVersions(applicationId) {
        const application = await this.getApplicationWithPackageName(applicationId);
        return application.version;
    },

    async getApplicationForDevice(deviceId) {
        return new Promise((resolve, reject) => {
            let url = store.state.getUrlWithParams(
                store.state.urlDeviceIdApps,
                {idDevice: deviceId}
            )
            return Vue.http.get(url)
                .then(response => resolve(response.body))
                .catch(e => reject(e));
        });
    },

    async setPlaystoreReady(packageName, versionCode, filePath) {
        return new Promise((resolve, reject) => {
            let url = store.state.getUrlWithParams(
                store.state.urlPlaystoreReady,
                {packageName: packageName}
            );
            return Vue.http.post(url, {versionCode, filePath})
                .then(response => resolve(response.body))
                .catch(e => reject(e));
        });
    },

    async getApplicationForCountryAndStoreUsingPlaystore(countryCode, storeId) {

        const response = await Vue.http.get(
            `${store.state.getUrlWithParams(store.state.urlApplicationForStore, {
                countryCode,
                storeId
            })}?existOnPlaystore=true`
        )
        return await Promise.all((response.body || []).map(async application => {
            try {
                return {
                    ...application,
                    icon: await UrlLogoUtils.getApplicationLogo(application)
                }
            } catch (error) {
                console.error(error)
            }
        }));
    },

    async getSpecificApplicationByCountryAndStore(countryCode, storeId) {
        const response = await Vue.http.get(`${store.state.getUrlWithParams(store.state.getSpecificApplication, {
            country: countryCode,
            store: storeId
        })}`);
        return (response.body || []);
    },
    async getPlaystoreLastStatusByTypeAndId(eventType, appId, appVersionCode) {
        let response;
        try {
            if (eventType === "GROUP") {
                response = await Vue.http.get(`${store.state.getUrlWithParams(store.state.urlAppApiPlaystoreEventsLastGroupsStatus, {
                    appId,
                    appVersionCode
                })}`);
            } else {
                response = await Vue.http.get(`${store.state.getUrlWithParams(store.state.urlAppApiPlaystoreEventsLastAppStatus, {appId})}`);
            }

            return response.body;
        } catch (e) {
            return {
                error: {
                    message: "Can't get the playstore value (api is down)"
                },
                state: FieldConstant.GROUP.PLAYSTORE_STATUS.ERROR
            };
        }
    },


    async isApplicationExist(packageName) {
        try {
            const response = await Vue.http.get(HttpRequestsUtils.getUrlWithParams(UrlConstant.urlApplicationIdOrPackageName, {idOrPackageName: packageName}));
            return response?.status === 200 && response?.body?.uid === packageName;
        } catch (e) {
            console.warn("PackageName " + packageName + " does not seem to exist.", e);
            if (e?.status !== 404) {
                throw e?.bodyText || e;
            }
            return false;
        }
    },

    saveApplication(application, appType = appTypeEnum.PRIVATE_APP) {
        return Vue.http.post(store.state.urlApplicationCreate, this.toSendApplication(application, appType)).then((response) => {
            const applicationResponse = response.body;
            sessionStorage.setItem('currentAppUid', response.body.uid);
            Vue.$ga.event({
                eventCategory: 'application',
                eventAction: 'create',
                eventLabel: JSON.stringify(this.toSendApplication(applicationResponse, appType))
            });
            let versionToSend = this.toSendVersion;
            if (appType === appTypeEnum.WEBVIEW) application.packageName = applicationResponse.uid;
            if (appType === appTypeEnum.PUBLIC_APP || appType === appTypeEnum.WEBVIEW) versionToSend = this.toSendPublicVersion;

            return Vue.http.post(`${store.state.urlVersionSaved}`, versionToSend({...application, ...applicationResponse})).then((response) => {
                Vue.$ga.event({
                    eventCategory: 'version',
                    eventAction: 'create',
                    eventLabel: JSON.stringify(this.toSendVersion(application))
                });
                return applicationResponse;
            });
        });
    },

    updateApplication(application, appType = appTypeEnum.PRIVATE_APP) {
        return Vue.http.post(store.state.urlApplicationUpdate, this.toSendApplication(application, appType)).then((application) => {
            Vue.$ga.event({
                eventCategory: 'application',
                eventAction: 'update',
                eventLabel: JSON.stringify(this.toSendApplication(application, appType))
            });
            return application.body;
        });
    },

    toSendApplication(application, appType = appTypeEnum.PRIVATE_APP) {
        const applicationToSave = {
            id: application.id || "",
            name: application.name,
            platform: application.platform?.toUpperCase() || "ANDROID",
            uid: application.packageName,
            applicationResponsible: application.applicationResponsible || store.state.user.displayName,
            descriptionInGb: findBestDescriptionToShow(application.descriptions, "EN"),
            blockUninstall: false,
            sdkVersion: application.sdkVersion,
            appType,
            applicationDescriptions: application.descriptions?.reduce(function (map, obj) {
                map[obj.code] = obj.changed || appType === appTypeEnum.PUBLIC_APP ? obj.content : "";
                return map;
            }, {}),
            logo: application.logo,
            displayMode: application.display,
            url: application.url,
            file: application.url,
        };
        return applicationToSave;
    },

    toSendVersion(application) {
        let descriptionToSend = [];
        let firstDescription = [];
        firstDescription.push("First Version");
        descriptionToSend.push({
            language: "en-GB",
            description: firstDescription,
        });

        return {
            versionId: application.versionId,
            appId: application.id,
            versionDescription: descriptionToSend,
            versionName: application.version,
            versionCode: application.versionCode,
            eligibility: application.arbo?.country?.toString() || "",
            file: application.url,
            uid: application.packageName,
            fileSize: application.fileSize,
            filePath: application.filePath,
            sdkVersion: application.sdkVersion,
            platform: application.platform,
            url: application.url
        };
    },

    toSendPublicVersion(application) {
        const descriptionToSend = [{
            language: "en-GB",
            description: ["Playstore version"],
        }];

        return {
            appId: application.id,
            uid: application.packageName,
            versionId: application.versionId,
            versionName: FieldConstant.APPLICATION.PLAYSTORE.PRODUCTION_VERSION_NAME,
            versionCode: 1,
            versionDescription: descriptionToSend,
            eligibility: "",
            platform: "ANDROID",
        }
    },

    async blacklistApplicationUrl() {
        let storedBlacklist = store.state.application.blacklistUrl;
        if (!storedBlacklist.regex && !storedBlacklist.loading) {
            store.dispatch('application/setBlacklistUrl', {loading: true});
            const regex = (await ParameterService.getStringValueBy("front.webview.blacklist.regex", "")).replace(/\\\\/g, '\\');
            store.dispatch('application/setBlacklistUrl', {regex});
            return regex;
        }
        return storedBlacklist.regex;
    }
}
