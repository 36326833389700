<template>
    <div id="application-deployment-group-table" class="column-group-detail">

        <deployment-groups-nav @displayDeploymentGroupsArray="displayDeploymentGroupsArray" :app="app"/>

        <retail-table v-if="displayGroups === buRetail"
                      :searched-groups="searchedGroups"
                      :get-states="getStates"
                      :stats-error="error"
                      :app="app"
                      :grant="grant"
                      :find-track="findTrack"
                      :set-groups-priority="setGroupsPriority"
                      @searchOnTable="searchOnTable"
                      @deleteGroupPopin="(popinView, valueTmp) => $emit('deleteGroupPopin', popinView, valueTmp)"
        />

        <services-table v-if="displayGroups === buServices"
                        :app="app"
                        :grant="grant"
                        :find-track="findTrack"
                        :android-track-ids="androidTrackIds"
                        :services-groups="servicesGroup"
        />

    </div>

</template>

<script>
import GroupAndroidTrackButton from  "../../Group/GroupAndroidTrackButton/groupAndroidTrackButton";
import FieldConstant from "../../../utils/field-constant";
import UpToDateColumn from "../../../pages/applications/details/group/up-to-date-column";
import TableRow from "../../../components/applications/deploymentGroupsTable/tableRow";
import TableRowServices from "../../../components/applications/deploymentGroupsTable/tableRowServices";
import groupService from "../../../service/group/group-service";
import Vue from "vue";
import DeploymentGroupsNav from "../../../components/Group/deployment-groups-nav";
import DeploymentGroupConstant from "../../../constant/deployment-group-constant";
import LocalStorageService from "../../../service/local-storage-service";
import LocalStorageConstant from "../../../constant/local-storage-constant";
import ServicesTable from "./servicesTable";
import RetailTable from "./retailTable";



export default {
    name: "groupsTable",
    components: {
        GroupAndroidTrackButton,
        UpToDateColumn,
        TableRow,
        DeploymentGroupsNav,
        TableRowServices,
        ServicesTable,
        RetailTable
    },
    props: {
        app: {
            type: Object
        },
        androidTrackIds: {
            type: Array
        },
        grant: {
            type: Function
        },
        versionsAvailable: {
            type: Number
        }
    },
    data() {
        return {
            countVersionsAvailable: this.versionsAvailable,
            tooltipModifyNoVersionsAvailable: this.$store.state.labels.GROUP_MODIFY_NO_VERSION_AVAILABLE,
            tooltipAddGroup: this.$store.state.labels.GROUP_ADD_IMPOSSIBLE,
            addGroupMaxLimit: 10,
            tooltipAddGroupMaxLimit: this.$store.state.labels.GROUP_ADD_IMPOSSIBLE_MAX_LIMIT,
            setGroupsPriority: true,
            searchedGroups: [],
            servicesGroup: [],
            CONSTANT: FieldConstant,
            GROUPS_AND_TRACKS_REFRESH_DELAY: 15000,
            groups: [{'retry': false, 'id': 'start'}],
            getStates: new Map(),
            error: null,
            buType: DeploymentGroupConstant.BU.RETAIL,
            buRetail: DeploymentGroupConstant.BU.RETAIL,
            buServices: DeploymentGroupConstant.BU.SERVICES
        }
    },
    computed: {
        displayGroups() {
            return this.buType;
        }
    },
    watch: {
        groups() {
            this.searchedGroups = this.groups
        }
    },
    methods: {
        findTrack(group) {
            return this.androidTrackIds?.find(track => track.trackId === group.androidTrackId)
        },
        searchOnTable(search) {
            this.searchedGroups = this.searchByName(this.groups, search);
        },
        searchByName(groups, termSearched) {
            if (termSearched) {
                return groups.filter(group => group.name?.toLowerCase().includes(termSearched?.toLowerCase()));
            }
            return groups;
        },
        async loadGroups(){
            this.$store.commit('group/resetCountGroups')
            const response = await Vue.http.get(this.$store.state.getUrlWithParams(this.$store.state.urlApplicationIdGroups,{idApplication : this.$route.params.id}));
            const applicationGroups = response.body.data;
            this.$emit('getGroups', applicationGroups);
            if(applicationGroups.length > 0) this.groups = [];
            applicationGroups.forEach(group => {
                this.mapApplicationGroupsApiResponse(group);
            })
        },
        async mapApplicationGroupsApiResponse(group){

            let result = [];

            let status_update;
            if (group.availability === FieldConstant.GROUP.UPDATABLE) {
                status_update = FieldConstant.GROUP.PROPOSE;
            } else if (group.availability === FieldConstant.GROUP.UPDATE_REQUIRED) {
                status_update = FieldConstant.GROUP.FORCE;
            }

            result.push({
                by: group.userCountry.toLowerCase(),
                lastUpdateUser: group.lastUpdateUser,
                lastUpdateDate: group.lastUpdateDate,
                id: group.id,
                availability: status_update,
                prio: group.priority,
                name: group.name,
                usedVersionName: group.version.versionName,
                activated: group.activated,
                countries: group.userCountry.split(' '),
                status: group.status,
                userCountry: group.userCountry,
                androidTrackId: group.androidTrackId,
                appVersionCode: group.version.versionCode,
                bu: group.bu
            });

            this.$store.commit("group/incrementCountGroups", group.bu)
            this.setGroupsPriority = this.$store.state.group.group.countGroup > 1;


            if (result.length > 0) {
                if(group.bu === DeploymentGroupConstant.BU.RETAIL) {
                    this.groups.push(result[0]);
                } else {
                    this.servicesGroup.push(result[0]);
                }
            }

            if(group.bu === DeploymentGroupConstant.BU.RETAIL) await this.getGroupState(result);

        },
        async getGroupState(group) {
            let stat = await groupService.getStats(group[0]).catch(e => ({groupId: group.id, state: { error: this.CONSTANT.ERROR_MESSAGES.LOADING_STATS }}))
            if(stat?.state?.error) this.error = stat.state.error
            this.getStates.set(stat?.groupId, stat?.state)
            this.actualStates = this.getStates;
            this.getStates = new Map(this.actualStates)
            this.getStates.set(stat?.groupId, stat?.state)
        },
        displayDeploymentGroupsArray(bu) {
            this.buType = bu
            LocalStorageService.setItem(LocalStorageConstant.BU, bu);
        }
    },
    async mounted() {
        await this.loadGroups();
    }

};
</script>

<style scoped>
.md-field {
    max-width: 300px;
    width: 300px;
    right :250px;
}
</style>

<style lang="scss" >
@import '~@/assets/style/color';

#application-deployment-group-table {
    > .md-table {
        overflow: unset !important;

        table, .md-table-content {
            overflow: unset !important;
        }
    }
    .md-table-cell-container {
        max-height: 65px;
        overflow: hidden;
        padding: 6px 20px 6px 20px;
    }

    .table-unset-hidden .md-table-cell-container {
        overflow: unset !important;
    }

    .table-unset-hidden > div > div {
        max-width: max-content;
    }
    .reduce-padding > div {
        padding: 5px;
    }
}

</style>
